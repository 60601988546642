import React, {Fragment} from 'react'
import {inject} from 'react-ioc'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {ReactRouterRoutingProvider} from '@bugsnag/react-router-performance'
import {type AxiosError, isAxiosError} from 'axios'
import {ROUTER} from 'config/routes'
import {SessionStore} from 'new/stores/SessionStore'
import {AppMode} from 'types/AppMode'

////////////////////////////////////////////////////////////////////////////////

export class BugsnagService {
  #sessionStore = inject(this, SessionStore)

  #bugsnagApiKey =
    import.meta.env.MODE === AppMode.PROD
      ? import.meta.env.VITE_BUGSNAG_API_KEY
      : (window.BUGSNAG_API_KEY ?? import.meta.env.VITE_BUGSNAG_API_KEY)

  #bugsnagEnabled =
    window.BUGSNAG_ENABLED ?? import.meta.env.VITE_BUGSNAG_ENABLED

  constructor() {
    this.#initBugsnag()
  }

  #initBugsnag() {
    if (this.#bugsnagApiKey && this.#bugsnagEnabled) {
      Bugsnag.start({
        apiKey: this.#bugsnagApiKey,
        plugins: [new BugsnagPluginReact()],
        releaseStage: import.meta.env.MODE,
        autoTrackSessions: false,
        enabledErrorTypes: {
          unhandledRejections: false,
        },
      })

      BugsnagPerformance.start({
        apiKey: this.#bugsnagApiKey,
        routingProvider: new ReactRouterRoutingProvider(ROUTER.routes),
      })
    }
  }

  get ErrorBoundary() {
    if (this.#bugsnagApiKey && this.#bugsnagEnabled) {
      return Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? Fragment
    }

    return Fragment
  }

  notifyError(error: AxiosError | Error, payload?: any) {
    const {organisation_uuid} = this.#sessionStore.getTokenInfo()

    // skip 401 errors
    if (isAxiosError(error) && error.response?.status === 401) {
      return
    }

    Bugsnag.notify(error, event => {
      let err = error

      if (isAxiosError(error)) {
        event.request.url = error.request.responseURL
        err = error.response?.data
      }

      event.setUser(organisation_uuid)
      event.addMetadata('payload', {
        error: err,
        payload,
      })
    })

    // eslint-disable-next-line no-console
    console.log(error)
  }
}
