import {inject} from 'react-ioc'
import {ctx} from 'new/ctx'
import {ApiStore} from 'new/stores/ApiStore'
import {type OnboardingData} from 'types/OnboardingData'

///////////////////////////////////////////////////////////////////////////////

export class OnboardingApiService {
  #apiStore = inject<ApiStore>(this, ApiStore)

  constructor() {
    ctx.onboardingApiService = this
  }

  async send(data: OnboardingData): Promise<void> {
    return this.#apiStore.post('/onboarding', data)
  }
}
