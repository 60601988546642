import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

///////////////////////////////////////////////////////////////////////////////

// Use it instead of "useState" hook if you need to suppress errors when calling
// "setState" on unmounted components
export const useAsyncState = <S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] => {
  const [state, setState] = useState(initialState)
  const isMounted = useRef(true)

  const setAsyncState: Dispatch<SetStateAction<S>> = (...args) => {
    if (isMounted.current) {
      setState(...args)
    }
  }

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return [state, setAsyncState]
}
