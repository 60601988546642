import {isFinite} from 'lodash-es'
import {type ColInfo} from 'xlsx'

///////////////////////////////////////////////////////////////////////////////

/**
 * Makes sure that the columns are wide enough to fit the content
 * for XLSX export
 */
export const autofitExportColumns = (json: Record<string, any>[]) => {
  const objectMaxLength: number[] = []
  const headers = new Set<string>()

  json.forEach(row => {
    Object.entries(row).forEach(([header, value], i) => {
      headers.add(header)
      let columnValue: {w?: number; v?: number} | number | string | undefined =
        value

      if (columnValue instanceof Object) {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        columnValue = columnValue.w || columnValue.v || 0
      }

      if (isFinite(columnValue)) {
        columnValue = columnValue?.toString()
      }

      const l = columnValue ? (columnValue as string).length : 0
      objectMaxLength[i] = Math.max(objectMaxLength[i] || 0, l)
    })
  })

  const headersArray = Array.from(headers)
  headersArray.forEach((value, j) => {
    objectMaxLength[j] = Math.max(objectMaxLength[j] || 0, value.length + 1, 10)
  })

  const wscols: ColInfo[] = objectMaxLength.map(wch => ({wch}))
  return {wscols, headers: headersArray}
}
