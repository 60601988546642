import {parsePhoneNumberFromString} from 'libphonenumber-js/max'
import {get} from 'lodash-es'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type PhoneNumberOptions = {
  valid?: boolean
  mobile?: true
}

///////////////////////////////////////////////////////////////////////////////

export const phoneNumber: ValidationRule<PhoneNumberOptions> = ({
  field,
  data,
  options,
}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  const {valid, mobile} = options ?? {}
  const phoneNumber = parsePhoneNumberFromString(value)

  // Checks if the phone number is "possible":
  // Only checks the phone number length, doesn't check the number digits
  // against any regular expressions.
  if (!phoneNumber?.isPossible()) {
    return {message: 'phone_number.not_possible'}
  }

  // Checks if the phone number is "valid":
  // Checks the phone number digits against all available regular expressions.
  if (valid && !phoneNumber.isValid()) {
    return {message: 'phone_number.not_valid'}
  }

  // Checks if the phone number type is "MOBILE".
  if (mobile && phoneNumber.getType() !== 'MOBILE') {
    return {message: 'phone_number.not_mobile'}
  }
}
