import {inject} from 'react-ioc'
import {type AxiosError} from 'axios'
import {makeAutoObservable, reaction} from 'mobx'
import {toGenerator} from 'lib/helpers/generators'
import {ctx} from 'new/ctx'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {BugsnagService} from 'new/services/analytics/BugsnagService'
import {UsersApiService} from 'new/services/UsersApiService'
import {type ChangePasswordData} from 'types/ChangePasswordData'
import {BackwardCompatibleMfaOption} from 'types/Mfa'
import {type User} from 'types/User'
import {SessionStore} from './SessionStore'

///////////////////////////////////////////////////////////////////////////////

export class CurrentUserStore {
  #usersApiService = inject<UsersApiService>(this, UsersApiService)
  #sessionStore = inject<SessionStore>(this, SessionStore)
  #analyticsEventService = inject(this, AnalyticsEventService)
  #bugsnagService = inject<BugsnagService>(this, BugsnagService)
  #disposers: (() => void)[] = []

  // state
  data?: User

  // fetching flags
  isFetching = false
  fetchingError?: Error

  // sending flags
  isSending = false
  sendingError?: AxiosError<any>
  changeEmailError?: AxiosError<any>
  changePasswordError?: AxiosError<any>

  constructor() {
    makeAutoObservable(this)

    ctx.currentUserStore = this

    this.#initEffects()

    // Trigger user fetching right away
    this.fetch()
  }

  *fetch() {
    try {
      this.isFetching = true
      this.fetchingError = undefined
      const userData = yield* toGenerator(
        this.#usersApiService.fetchCurrentUser()
      )

      if (userData) {
        this.data = {
          ...userData,
          two_factor_auth_choice:
            userData.two_factor_auth_choice === BackwardCompatibleMfaOption.APP
              ? BackwardCompatibleMfaOption.TOTP
              : userData.two_factor_auth_choice,
        }
      }

      this.#analyticsEventService
        .identifyUser(this.data?.uuid)
        .addUserProperties({
          country_code: this.data?.country_code,
          locale: this.data?.locale,
          salutation: this.data?.salutation,
          internal_user: this.#isInternalUser(),
        })
    } catch (error: any) {
      this.#bugsnagService.notifyError(error)
      this.fetchingError = error
      this.data = undefined // reset data
    } finally {
      this.isFetching = false
    }
  }

  *save(data: Partial<User>) {
    try {
      this.isSending = true
      this.sendingError = undefined

      yield this.#usersApiService.save(data)
      this.isSending = false

      yield this.fetch() // re-fetch data
    } catch (error: any) {
      this.#bugsnagService.notifyError(error, data)
      this.sendingError = error
      this.isSending = false
    }
  }

  *changeEmail(data: string) {
    try {
      this.isSending = true
      this.changeEmailError = undefined
      yield this.#usersApiService.changeEmail(data)
    } catch (error: any) {
      this.#bugsnagService.notifyError(error, data)
      this.changeEmailError = error
    } finally {
      this.isSending = false
    }
  }

  *changePassword(data: ChangePasswordData) {
    try {
      this.isSending = true
      this.changePasswordError = undefined
      yield this.#usersApiService.changePassword(data)
    } catch (error: any) {
      this.#bugsnagService.notifyError(error, data)
      this.changePasswordError = error
    } finally {
      this.isSending = false
    }
  }

  get isAuthenticated() {
    if (this.data) {
      return true
    }

    if (this.isFetching) {
      return undefined
    }

    return false
  }

  #isInternalUser() {
    const INTERNAL_EMAIL_DOMAINS = [
      '@raisenow.com',
      '@raisenow.ch',
      '@raisenow.at',
      '@raisenow.de',
    ]
    return INTERNAL_EMAIL_DOMAINS.some(domain =>
      this.data?.email.endsWith(domain)
    )
  }

  #initEffects() {
    this.#disposers.push(
      reaction(
        () => this.data?.uuid,
        userUuid => {
          if (!userUuid) {
            return
          }

          // Update token info with user.uuid
          this.#sessionStore.updateTokenInfo({
            user_uuid: userUuid,
            organisation_uuid: this.data?.organisation_uuid,
          })
          this.#sessionStore.restoreLocation(userUuid)
        },
        {
          fireImmediately: false,
        }
      )
    )
  }

  dispose() {
    this.#disposers.forEach(fn => fn())
  }
}
