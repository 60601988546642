// Source: https://www.ecb.europa.eu/stats/money/aggregates/anacredit/shared/pdf/List_postal_code_formatting_rules_and_regular_expressions.xlsx
export const postalCodes = [
  {
    Country: 'Andorra',
    ISO: 'AD',
    NationalTerm: 'Código postal',
    Regex: /^AD\d{3}$/,
    ReportingFormat: 'AD100',
  },
  {
    Country: 'Afghanistan',
    ISO: 'AF',
    NationalTerm: 'Post code',
    Regex: /^\d{4}$/,
    ReportingFormat: '1057',
  },
  {
    Country: 'Anguilla',
    ISO: 'AI',
    NationalTerm: 'Post code',
    Regex: /^(AI-2640)$/,
    ReportingFormat: 'AI-2640',
  },
  {
    Country: 'Albania',
    ISO: 'AL',
    NationalTerm: 'Kodi postar',
    Regex: /^\d{4}$/,
    ReportingFormat: '5300',
  },
  {
    Country: 'Armenia',
    ISO: 'AM',
    NationalTerm: 'Postal index',
    Regex: /^(\d{4})\b|\b(\d{6})$/,
    ReportingFormat: '0010, 001011',
  },
  {
    Country: 'Antarctica',
    ISO: 'AQ',
    NationalTerm: 'Postcode',
    Regex: /^7151$/,
    ReportingFormat: '7151',
  },
  {
    Country: 'Argentina',
    ISO: 'AR',
    NationalTerm: 'CPA',
    Regex: /^([A-Z]\d{4}[A-Z]{3})\b|\b([A-Z]\d{4})$/,
    ReportingFormat: 'C1425CLA, U9000',
  },
  {
    Country: 'American Samoa',
    ISO: 'AS',
    NationalTerm: 'Zip code (US)',
    Regex: /^967\d{2}(-\d{4})?$/,
    ReportingFormat: '96799, 96799-9999',
  },
  {
    Country: 'Austria',
    ISO: 'AT',
    NationalTerm: 'PLZ',
    Regex: /^\d{4}$/,
    ReportingFormat: '1010',
  },
  {
    Country: 'Australia',
    ISO: 'AU',
    NationalTerm: 'Postcode',
    Regex: /^\d{4}$/,
    ReportingFormat: '2599',
  },
  {
    Country: 'Azerbaijan',
    ISO: 'AZ',
    NationalTerm: 'İndeks',
    Regex: /^(AZ)(\d{4})|(AZ )(\d{4})$/,
    ReportingFormat: 'AZ 1000, AZ1000',
  },
  {
    Country: 'Bosnia and Herzegovina',
    ISO: 'BA',
    NationalTerm: 'Poštanski broj',
    Regex: /^\d{5}$/,
    ReportingFormat: '71000',
  },
  {
    Country: 'Barbados',
    ISO: 'BB',
    NationalTerm: 'Postal code',
    Regex: /^BB\d{5}$/,
    ReportingFormat: 'BB15094',
  },
  {
    Country: 'Bangladesh',
    ISO: 'BD',
    NationalTerm: 'Post code',
    Regex: /^\d{4}$/,
    ReportingFormat: '1219',
  },
  {
    Country: 'Belgium',
    ISO: 'BE',
    NationalTerm: 'Code postal',
    Regex: /^\d{4}$/,
    ReportingFormat: '1049',
  },
  {
    Country: 'Burkina Faso',
    ISO: 'BF',
    NationalTerm: '-',
    Regex: /^[1-9]\d{4}$/,
    ReportingFormat: '99999',
  },
  {
    Country: 'Bulgaria',
    ISO: 'BG',
    NationalTerm: 'Пощенски код',
    Regex: /^\d{4}$/,
    ReportingFormat: '1000',
  },
  {
    Country: 'Bahrain',
    ISO: 'BH',
    NationalTerm: 'Post code',
    Regex: /^\d{3}\d?$/,
    ReportingFormat: '317, 1216',
  },
  {
    Country: 'Bermuda',
    ISO: 'BM',
    NationalTerm: 'Post codes',
    Regex: /^[A-Z]{2} \d{2}$/,
    ReportingFormat: 'CR 03',
  },
  {
    Country: 'Brunei Darussalam',
    ISO: 'BN',
    NationalTerm: 'Kod',
    Regex: /^[A-Z]{2}\d{4}$/,
    ReportingFormat: 'KB2333',
  },
  {
    Country: 'Brazil',
    ISO: 'BR',
    NationalTerm: 'CEP',
    Regex: /^[0-9]{5}-[0-9]{3}$/,
    ReportingFormat: '28999-999',
  },
  {
    Country: 'Bhutan',
    ISO: 'BT',
    NationalTerm: 'Post code',
    Regex: /^\d{5}$/,
    ReportingFormat: '31002',
  },
  {
    Country: 'Belarus',
    ISO: 'BY',
    NationalTerm: 'Индекс',
    Regex: /^\d{6}$/,
    ReportingFormat: '231300',
  },
  {
    Country: 'Canada',
    ISO: 'CA',
    NationalTerm: 'Post code',
    Regex: /^[A-Z][0-9][A-Z] [0-9][A-Z][0-9]$/,
    ReportingFormat: 'K1A 0T6',
  },
  {
    Country: 'Cocos (Keeling) Islands',
    ISO: 'CC',
    NationalTerm: 'Post code',
    Regex: /^6799$/,
    ReportingFormat: '6799',
  },
  {
    Country: 'Switzerland',
    ISO: 'CH',
    NationalTerm: 'PLZ / Code postal',
    Regex: /^[1-9]\d{3}$/,
    ReportingFormat: '8050',
  },
  {
    Country: 'Chile',
    ISO: 'CL',
    NationalTerm: 'Código postal',
    Regex: /^\d{7}$/,
    ReportingFormat: '9340000',
  },
  {
    Country: 'China',
    ISO: 'CN',
    NationalTerm: '郵政編碼',
    Regex: /^\d{6}$/,
    ReportingFormat: '710000',
  },
  {
    Country: 'Colombia',
    ISO: 'CO',
    NationalTerm: 'Código postal',
    Regex: /^\d{6}$/,
    ReportingFormat: '111121',
  },
  {
    Country: 'Costa Rica',
    ISO: 'CR',
    NationalTerm: 'Código postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '10101',
  },
  {
    Country: 'Cuba',
    ISO: 'CU',
    NationalTerm: 'Código postal',
    Regex: /^(CP)?\d{5}$/,
    ReportingFormat: 'CP10400',
  },
  {
    Country: 'Cabo Verde',
    ISO: 'CV',
    NationalTerm: 'CIP',
    Regex: /^\d{4}$/,
    ReportingFormat: '5110',
  },
  {
    Country: 'Christmas Island',
    ISO: 'CX',
    NationalTerm: 'Postcode',
    Regex: /^6798$/,
    ReportingFormat: '6798',
  },
  {
    Country: 'Cyprus',
    ISO: 'CY',
    NationalTerm: 'Postcode',
    Regex: /^[1-9]\d{3}$/,
    ReportingFormat: '4999',
  },
  {
    Country: 'Czech Republic',
    ISO: 'CZ',
    NationalTerm: 'PSČ',
    Regex: /^[1-7][0-9]{2} [0-9]{2}\b|\b[1-7][0-9]{4}$/,
    ReportingFormat: '160 00, 16000',
  },
  {
    Country: 'Germany',
    ISO: 'DE',
    NationalTerm: 'PLZ',
    Regex: /^\d{5}$/,
    ReportingFormat: '60320',
  },
  {
    Country: 'Denmark',
    ISO: 'DK',
    NationalTerm: 'Postnummer',
    Regex: /^\d{4}$/,
    ReportingFormat: '2000',
  },
  {
    Country: 'Dominican Republic',
    ISO: 'DO',
    NationalTerm: 'Código postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '10103',
  },
  {
    Country: 'Algeria',
    ISO: 'DZ',
    NationalTerm: 'Code postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '16000',
  },
  {
    Country: 'Ecuador',
    ISO: 'EC',
    NationalTerm: 'Código postal',
    Regex: /^\d{6}$/,
    ReportingFormat: '170515',
  },
  {
    Country: 'Estonia',
    ISO: 'EE',
    NationalTerm: 'Postiindeks',
    Regex: /^\d{5}$/,
    ReportingFormat: '10111',
  },
  {
    Country: 'Egypt',
    ISO: 'EG',
    NationalTerm: 'البريدي الرمز',
    Regex: /^\d{5}$/,
    ReportingFormat: '12411',
  },
  {
    Country: 'Spain',
    ISO: 'ES',
    NationalTerm: 'Código postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '28006',
  },
  {
    Country: 'Ethiopia',
    ISO: 'ET',
    NationalTerm: 'Postal code',
    Regex: /^\d{4}$/,
    ReportingFormat: '3020',
  },
  {
    Country: 'Finland',
    ISO: 'FI',
    AdditionalTerritories: 'Åland Islands',
    NationalTerm: 'Postinumero',
    Regex: /^\d{5}$/,
    ReportingFormat: '180',
  },
  {
    Country: 'Falkland Islands (Malvinas)',
    ISO: 'FK',
    NationalTerm: 'Postcode',
    Regex: /^(FIQQ 1ZZ)$/,
    ReportingFormat: 'FIQQ 1ZZ',
  },
  {
    Country: 'Micronesia, Federated States of',
    ISO: 'FM',
    NationalTerm: 'Zip code (US)',
    Regex: /^9694\d{1}(-\d{4})?$/,
    ReportingFormat: '96942, 96942-9999',
  },
  {
    Country: 'Faroe Islands',
    ISO: 'FO',
    NationalTerm: 'Postnummur (DK)',
    Regex: /^\d{3}$/,
    ReportingFormat: '927',
  },
  {
    Country: 'France',
    ISO: 'FR',
    AdditionalTerritories:
      'Guadeloupe, French Guiana, Réunion, Martinique, Mayotte, Monaco, Saint Barthélemy, Saint Martin (French part), Saint Pierre and Miquelon',
    NationalTerm: 'Code postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '75008',
  },
  {
    Country: 'United Kingdom of Great Britain and Northern Ireland (the)',
    ISO: 'GB',
    NationalTerm: 'Postcode',
    Regex:
      /([G][I][R] 0[A]{2})|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2})$/,
    ReportingFormat: 'DT3 6GB, L2 2DP',
  },
  {
    Country: 'Georgia',
    ISO: 'GE',
    NationalTerm: 'Post code',
    Regex: /^\d{4}$/,
    ReportingFormat: '100',
  },
  {
    Country: 'Guernsey',
    ISO: 'GG',
    NationalTerm: 'Postcode (UK)',
    Regex:
      /(GY)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}$/,
    ReportingFormat: 'GY1 3HR',
  },
  {
    Country: 'Gibraltar',
    ISO: 'GI',
    NationalTerm: 'Postcode (UK)',
    Regex: /^(GX11 1AA)$/,
    ReportingFormat: 'GX11 1AA',
  },
  {
    Country: 'Greenland',
    ISO: 'GL',
    NationalTerm: 'Postnummer (DK)',
    Regex: /^39\d{2}$/,
    ReportingFormat: '3905',
  },
  {
    Country: 'Guinea',
    ISO: 'GN',
    NationalTerm: 'Code postal',
    Regex: /^\d{3}$/,
    ReportingFormat: '1',
  },
  {
    Country: 'Greece',
    ISO: 'GR',
    NationalTerm: 'T.K.',
    Regex: /^(\d{3}) \d{2}\b|\b\d{5}$/,
    ReportingFormat: '241 00, 24100',
  },
  {
    Country: 'South Georgia and the South Sandwich Islands',
    ISO: 'GS',
    NationalTerm: 'Post code',
    Regex: /^(SIQQ 1ZZ)$/,
    ReportingFormat: 'SIQQ 1ZZ',
  },
  {
    Country: 'Guatemala',
    ISO: 'GT',
    NationalTerm: 'Código postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '1002',
  },
  {
    Country: 'Guam',
    ISO: 'GU',
    NationalTerm: 'Zip code (US)',
    Regex: /^((969)[1-3][0-2])(-\d{4})?$/,
    ReportingFormat: '96911, 96911-9999',
  },
  {
    Country: 'Guinea-Bissau',
    ISO: 'GW',
    NationalTerm: 'Código postal',
    Regex: /^\d{4}$/,
    ReportingFormat: '1000',
  },
  {
    Country: 'Hong Kong',
    ISO: 'HK',
    NationalTerm: '郵政編碼',
    Regex: /^(999077)$/,
    ReportingFormat: '999077',
  },
  {
    Country: 'Heard Island and McDonald Islands',
    ISO: 'HM',
    NationalTerm: 'Postcode',
    Regex: /^(7151)$/,
    ReportingFormat: '7151',
  },
  {
    Country: 'Honduras',
    ISO: 'HN',
    NationalTerm: 'Código postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '34101',
  },
  {
    Country: 'Croatia',
    ISO: 'HR',
    NationalTerm: 'Poštanski broj',
    Regex: /^[1-5]\d{4}$/,
    ReportingFormat: '21000',
  },
  {
    Country: 'Haiti',
    ISO: 'HT',
    NationalTerm: 'Code postal',
    Regex: /^(HT)(\d{4})\b|\b(HT) (\d{4})$/,
    ReportingFormat: 'HT1440, HT 1440',
  },
  {
    Country: 'Hungary',
    ISO: 'HU',
    NationalTerm: 'Irányítószám',
    Regex: /^[1-9]\d{3}$/,
    ReportingFormat: '2310',
  },
  {
    Country: 'Indonesia',
    ISO: 'ID',
    NationalTerm: 'Kodepos',
    Regex: /^[1-9]\d{4}$/,
    ReportingFormat: '15360',
  },
  {
    Country: 'Israel',
    ISO: 'IL',
    NationalTerm: 'Postal code',
    Regex: /^\d{7}$/,
    ReportingFormat: '1029200',
  },
  {
    Country: 'Isle of Man',
    ISO: 'IM',
    NationalTerm: 'Postcode (UK)',
    Regex:
      /(IM)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}$/,
    ReportingFormat: 'IM5 1JS',
  },
  {
    Country: 'India',
    ISO: 'IN',
    NationalTerm: 'PIN code',
    Regex: /^[1-9]\d{5}$/,
    ReportingFormat: '500012',
  },
  {
    Country: 'British Indian Ocean Territory',
    ISO: 'IO',
    NationalTerm: 'Postcode',
    Regex: /^(BB9D 1ZZ)$/,
    ReportingFormat: 'BB9D 1ZZ',
  },
  {
    Country: 'Iraq',
    ISO: 'IQ',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '58019',
  },
  {
    Country: 'Iran, Islamic Republic of',
    ISO: 'IR',
    NationalTerm: 'پستی کد',
    Regex: /^\d{5}[\-]?\d{5}$/, //eslint-disable-line
    ReportingFormat: '9187158198, 15119-43943',
  },
  {
    Country: 'Iceland',
    ISO: 'IS',
    NationalTerm: 'Póstnúmer',
    Regex: /^[1-9]\d{2}$/,
    ReportingFormat: '101',
  },
  {
    Country: 'Italy',
    ISO: 'IT',
    NationalTerm: 'CAP',
    Regex: /^\d{5}$/,
    ReportingFormat: '36051',
  },
  {
    Country: 'Jersey',
    ISO: 'JE',
    NationalTerm: 'Postcode (UK)',
    Regex: /^JE[0-9]{1}[\s]([\d][A-Z]{2})$/,
    ReportingFormat: 'JE1 1AG',
  },
  {
    Country: 'Jamaica',
    ISO: 'JM',
    NationalTerm: 'Postal code',
    Regex: /^(JM)[A-Z]{3}\d{2}$/,
    ReportingFormat: 'JMAAW19',
  },
  {
    Country: 'Jordan',
    ISO: 'JO',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '11118',
  },
  {
    Country: 'Japan',
    ISO: 'JP',
    NationalTerm: '郵便番号',
    Regex: /^(\d{3}-\d{4})$/,
    ReportingFormat: '408-0307',
  },
  {
    Country: 'Kenya',
    ISO: 'KE',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '40406',
  },
  {
    Country: 'Kyrgyzstan',
    ISO: 'KG',
    NationalTerm: 'Почтовый индекс',
    Regex: /^\d{6}$/,
    ReportingFormat: '720020',
  },
  {
    Country: 'Cambodia',
    ISO: 'KH',
    NationalTerm: 'Postal code',
    Regex: /^\d{5,6}$/,
    ReportingFormat: '01501, 010102, 120209',
  },
  {
    Country: 'Kiribati',
    ISO: 'KI',
    NationalTerm: '-',
    Regex: /^KI\d{4}$/,
    ReportingFormat: 'KI0107',
  },
  {
    Country: 'Korea, Republic of',
    ISO: 'KR',
    NationalTerm: '우편번호',
    Regex: /^\d{5}$/,
    ReportingFormat: '11962',
  },
  {
    Country: 'Kuwait',
    ISO: 'KW',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '60000',
  },
  {
    Country: 'Cayman Islands',
    ISO: 'KY',
    NationalTerm: 'Postcode (UK)',
    Regex: /^[K][Y][0-9]{1}[-]([0-9]){4}$/,
    ReportingFormat: 'KY1-1800',
  },
  {
    Country: 'Kazakhstan',
    ISO: 'KZ',
    NationalTerm: 'Почтового индекса',
    Regex: /^([A-Z]\d{2}[A-Z]\d[A-Z]\d)\b|\b(\d{6})$/,
    ReportingFormat: 'A10A5T4, 010010',
  },
  {
    Country: "Lao People's Democratic Republic",
    ISO: 'LA',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '13000',
  },
  {
    Country: 'Lebanon',
    ISO: 'LB',
    NationalTerm: 'Code postal',
    Regex: /^\d{4}( \d{4})?$/,
    ReportingFormat: '2038 3054, 1103',
  },
  {
    Country: 'Saint Lucia',
    ISO: 'LC',
    NationalTerm: '-',
    Regex: /^LC\d{2} \d{3}$/,
    ReportingFormat: 'LC05 201',
  },
  {
    Country: 'Liechtenstein',
    ISO: 'LI',
    NationalTerm: 'PLZ (CH)',
    Regex: /^\d{4}$/,
    ReportingFormat: '9490',
  },
  {
    Country: 'Sri Lanka',
    ISO: 'LK',
    NationalTerm: 'Postcode',
    Regex: /^\d{5}$/,
    ReportingFormat: '80212',
  },
  {
    Country: 'Liberia',
    ISO: 'LR',
    NationalTerm: 'Postal code',
    Regex: /^\d{4}$/,
    ReportingFormat: '1000',
  },
  {
    Country: 'Lesotho',
    ISO: 'LS',
    NationalTerm: 'Postal code',
    Regex: /^\d{3}$/,
    ReportingFormat: '100',
  },
  {
    Country: 'Lithuania',
    ISO: 'LT',
    NationalTerm: 'Pašto kodas',
    Regex: /^((LT)[\-])?(\d{5})/, //eslint-disable-line
    ReportingFormat: 'LT-01100, 01100',
  },
  {
    Country: 'Luxembourg',
    ISO: 'LU',
    NationalTerm: 'Code postal',
    Regex: /^((L)[\-])?(\d{4})/, //eslint-disable-line
    ReportingFormat: '1019, L-2530',
  },
  {
    Country: 'Latvia',
    ISO: 'LV',
    NationalTerm: 'Pasta indekss',
    Regex: /^((LV)[\-])?(\d{4})/, //eslint-disable-line
    ReportingFormat: 'LV-1010, 1010',
  },
  {
    Country: 'Morocco',
    ISO: 'MA',
    AdditionalTerritories: 'Western Sahara',
    NationalTerm: 'Code postal',
    Regex: /^[1-9]\d{4}$/,
    ReportingFormat: '20192',
  },
  {
    Country: 'Moldova, Republic of',
    ISO: 'MD',
    NationalTerm: 'Coduri poştale',
    Regex: /^(MD[\-]?)?(\d{4})/, //eslint-disable-line
    ReportingFormat: 'MD2001, MD-2001, 2001',
  },
  {
    Country: 'Montenegro',
    ISO: 'ME',
    NationalTerm: 'Поштански код',
    Regex: /^\d{5}$/,
    ReportingFormat: '81250',
  },
  {
    Country: 'Madagascar',
    ISO: 'MG',
    NationalTerm: 'Code postal',
    Regex: /^\d{3}$/,
    ReportingFormat: 101,
  },
  {
    Country: 'Marshall Islands',
    ISO: 'MH',
    NationalTerm: 'Zip code (US)',
    Regex: /^((969)[6-7][0-9])(-\d{4})?$/,
    ReportingFormat: '96960, 96960-9999',
  },
  {
    Country: 'North Macedonia',
    ISO: 'MK',
    NationalTerm: 'Поштенски код',
    Regex: /^\d{4}$/,
    ReportingFormat: '1045',
  },
  {
    Country: 'Myanmar',
    ISO: 'MM',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '11121',
  },
  {
    Country: 'Mongolia',
    ISO: 'MN',
    NationalTerm: 'Zipcode',
    Regex: /^\d{5}$/,
    ReportingFormat: '16080',
  },
  {
    Country: 'Northern Mariana Islands',
    ISO: 'MP',
    NationalTerm: 'Zip code (US)',
    Regex: /^9695\d{1}(-\d{4})?$/,
    ReportingFormat: '96950, 96950-9999',
  },
  {
    Country: 'Montserrat',
    ISO: 'MS',
    NationalTerm: 'Post code',
    Regex: /^MSR\d{4}$/,
    ReportingFormat: 'MSR1120',
  },
  {
    Country: 'Malta',
    ISO: 'MT',
    NationalTerm: 'Kodiċi Postali',
    Regex:
      /^[A-Z]{3} [0-9]{4}\b|\b[A-Z]{2}[0-9]{2}\b|\b[A-Z]{2} [0-9]{2}\b|\b[A-Z]{3}[0-9]{4}\b|\b[A-Z]{3}[0-9]{2}\b|\b[A-Z]{3} [0-9]{2}$/,
    ReportingFormat: 'VLT 1117, TP01, TP 01, RBT1676, QRM09, BKR 01',
  },
  {
    Country: 'Mauritius',
    ISO: 'MU',
    NationalTerm: 'Post code',
    Regex: /^([0-9A-R]\d{4})$/,
    ReportingFormat: 'A0000, 20101',
  },
  {
    Country: 'Maldives',
    ISO: 'MV',
    NationalTerm: 'Post code',
    Regex: /^\d{5}$/,
    ReportingFormat: '20195',
  },
  {
    Country: 'Malawi',
    ISO: 'MW',
    NationalTerm: '-',
    Regex: /^\d{6}$/,
    ReportingFormat: '101100, 307100',
  },
  {
    Country: 'Mexico',
    ISO: 'MX',
    NationalTerm: 'Código postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '97229',
  },
  {
    Country: 'Malaysia',
    ISO: 'MY',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '50050',
  },
  {
    Country: 'Mozambique',
    ISO: 'MZ',
    NationalTerm: 'Código postal',
    Regex: /^\d{4}$/,
    ReportingFormat: '1104',
  },
  {
    Country: 'Namibia',
    ISO: 'NA',
    NationalTerm: '-',
    Regex: /^\d{5}$/,
    ReportingFormat: '10003',
  },
  {
    Country: 'New Caledonia',
    ISO: 'NC',
    NationalTerm: 'Code postal (FR)',
    Regex: /^988\d{2}$/,
    ReportingFormat: '98814',
  },
  {
    Country: 'Niger',
    ISO: 'NE',
    NationalTerm: 'Code postal',
    Regex: /^\d{4}$/,
    ReportingFormat: '8001',
  },
  {
    Country: 'Norfolk Island',
    ISO: 'NF',
    NationalTerm: 'Post code',
    Regex: /^(2899)$/,
    ReportingFormat: '2899',
  },
  {
    Country: 'Nigeria',
    ISO: 'NG',
    NationalTerm: 'Postal code',
    Regex: /^[1-9]\d{5}$/,
    ReportingFormat: '100001',
  },
  {
    Country: 'Nicaragua',
    ISO: 'NI',
    NationalTerm: 'Código postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '11001',
  },
  {
    Country: 'Netherlands',
    ISO: 'NL',
    NationalTerm: 'Postcode',
    Regex: /^[1-9]\d{3} [A-Z]{2}\b|\b[1-9]\d{3}[A-Z]{2}$/,
    ReportingFormat: '1011 AC, 1011AC',
  },
  {
    Country: 'Norway',
    ISO: 'NO',
    AdditionalTerritories: 'Svalbard and Jan Mayen',
    NationalTerm: 'Postnummer',
    Regex: /^\d{4}$/,
    ReportingFormat: '5262',
  },
  {
    Country: 'Nepal',
    ISO: 'NP',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '44600',
  },
  {
    Country: 'Nauru',
    ISO: 'NR',
    NationalTerm: '-',
    Regex: /^(NRU68)$/,
    ReportingFormat: 'NRU68',
  },
  {
    Country: 'Niue',
    ISO: 'NU',
    NationalTerm: '-',
    Regex: /^(9974)$/,
    ReportingFormat: '9974',
  },
  {
    Country: 'New Zealand',
    ISO: 'NZ',
    NationalTerm: 'Postcode',
    Regex: /^\d{4}$/,
    ReportingFormat: '8041',
  },
  {
    Country: 'Oman',
    ISO: 'OM',
    NationalTerm: 'Postal code',
    Regex: /^\d{3}$/,
    ReportingFormat: '112',
  },
  {
    Country: 'Panama',
    ISO: 'PA',
    NationalTerm: '-',
    Regex: /^\d{4}$/,
    ReportingFormat: '0601, 1001',
  },
  {
    Country: 'Peru',
    ISO: 'PE',
    NationalTerm: 'Código postal',
    Regex: /^\d{5}$/,
    ReportingFormat: '15001',
  },
  {
    Country: 'French Polynesia',
    ISO: 'PF',
    NationalTerm: 'Code postal (FR)',
    Regex: /^((987)\d{2})$/,
    ReportingFormat: '98755',
  },
  {
    Country: 'Papua New Guinea',
    ISO: 'PG',
    NationalTerm: 'Postal code',
    Regex: /^\d{3}$/,
    ReportingFormat: '244',
  },
  {
    Country: 'Philippines',
    ISO: 'PH',
    NationalTerm: 'Zipcode',
    Regex: /^\d{4}$/,
    ReportingFormat: '4104',
  },
  {
    Country: 'Pakistan',
    ISO: 'PK',
    NationalTerm: 'Post code',
    Regex: /^[1-9]\d{4}$/,
    ReportingFormat: '75600',
  },
  {
    Country: 'Poland',
    ISO: 'PL',
    NationalTerm: 'Kod pocztowy',
    Regex: /^[0-9]{2}[-]([0-9]){3}$/,
    ReportingFormat: '87-100',
  },
  {
    Country: 'Pitcairn',
    ISO: 'PN',
    NationalTerm: 'Post code',
    Regex: /^(PCR9 1ZZ)$/,
    ReportingFormat: 'PCR9 1ZZ',
  },
  {
    Country: 'Palestine, State of',
    ISO: 'PS',
    NationalTerm: 'Post code',
    Regex: /^(P[1-9]\d{6})\b|\b(\d{3}-\d{3})$/,
    ReportingFormat: '600-699, P3600700',
  },
  {
    Country: 'Portugal',
    ISO: 'PT',
    NationalTerm: 'Código postal',
    Regex: /^[1-9]\d{3}((-)\d{3})$/,
    ReportingFormat: '1000-260',
  },
  {
    Country: 'Palau',
    ISO: 'PW',
    NationalTerm: 'Postal code',
    Regex: /^(96939|96940)$/,
    ReportingFormat: '96939, 96940',
  },
  {
    Country: 'Paraguay',
    ISO: 'PY',
    NationalTerm: 'Código postal',
    Regex: /^\d{4}$/,
    ReportingFormat: '3180',
  },
  {
    Country: 'Romania',
    ISO: 'RO',
    NationalTerm: 'Cod poştal',
    Regex: /^\d{6}$/,
    ReportingFormat: '507085',
  },
  {
    Country: 'Serbia',
    ISO: 'RS',
    NationalTerm: 'Поштански код',
    Regex: /^\d{5,6}$/,
    ReportingFormat: '24430, 456769',
  },
  {
    Country: 'Russian Federation',
    ISO: 'RU',
    NationalTerm: 'Почтовый индекс',
    Regex: /^\d{6}$/,
    ReportingFormat: '385100',
  },
  {
    Country: 'Saudi Arabia',
    ISO: 'SA',
    NationalTerm: 'Postal code',
    Regex: /^[1-8]\d{4}([\-]\d{4})?/, //eslint-disable-line
    ReportingFormat: '11564, 75311-8538',
  },
  {
    Country: 'Sudan',
    ISO: 'SD',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '13315',
  },
  {
    Country: 'Sweden',
    ISO: 'SE',
    NationalTerm: 'Postnummer',
    Regex: /^[1-9]\d{2} \d{2}$/,
    ReportingFormat: '113 51',
  },
  {
    Country: 'Singapore',
    ISO: 'SG',
    NationalTerm: 'Postal code',
    Regex: /^\d{6}$/,
    ReportingFormat: '570150',
  },
  {
    Country: 'Saint Helena, Ascension and Tristan da Cunha',
    ISO: 'SH',
    NationalTerm: 'Postcode (UK)',
    Regex: /^(ASCN 1ZZ|TDCU 1ZZ|STHL 1ZZ)$/,
    ReportingFormat: 'ASCN 1ZZ, TDCU 1ZZ, STHL 1ZZ',
  },
  {
    Country: 'Slovenia',
    ISO: 'SI',
    NationalTerm: 'Poštna številka',
    Regex: /^[1-9]\d{3}$/,
    ReportingFormat: '8341',
  },
  {
    Country: 'Slovakia',
    ISO: 'SK',
    NationalTerm: 'PSČ',
    Regex: /^(\d{3} \d{2})\b|\b\d{5}$/,
    ReportingFormat: '811 01, 81101',
  },
  {
    Country: 'San Marino',
    ISO: 'SM',
    NationalTerm: 'CAP (IT)',
    Regex: /^(4789\d)$/,
    ReportingFormat: '47894',
  },
  {
    Country: 'Senegal',
    ISO: 'SN',
    NationalTerm: 'Code postal',
    Regex: /^[1-8]\d{4}$/,
    ReportingFormat: '10200',
  },
  {
    Country: 'South Sudan',
    ISO: 'SS',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '11111',
  },
  {
    Country: 'El Salvador',
    ISO: 'SV',
    NationalTerm: 'Código postal',
    Regex: /^\d{4}$/,
    ReportingFormat: '1201',
  },
  {
    Country: 'Swaziland',
    ISO: 'SZ',
    NationalTerm: 'Postal code',
    Regex: /^([A-Z]\d{3})$/,
    ReportingFormat: 'M201',
  },
  {
    Country: 'Turks and Caicos Islands',
    ISO: 'TC',
    NationalTerm: 'Postcode (UK)',
    Regex: /^(TKCA 1ZZ)$/,
    ReportingFormat: 'TKCA 1ZZ',
  },
  {
    Country: 'Thailand',
    ISO: 'TH',
    NationalTerm: 'รหัสไปรษณีย์',
    Regex: /^\d{5}$/,
    ReportingFormat: '10240',
  },
  {
    Country: 'Tajikistan',
    ISO: 'TJ',
    NationalTerm: 'Нишонаи почта',
    Regex: /^7\d{5}$/,
    ReportingFormat: '799999',
  },
  {
    Country: 'Turkmenistan',
    ISO: 'TM',
    NationalTerm: 'Почтовый индекс',
    Regex: /^7\d{5}$/,
    ReportingFormat: '745180',
  },
  {
    Country: 'Tunisia',
    ISO: 'TN',
    NationalTerm: 'Code postal',
    Regex: /^\d{4}$/,
    ReportingFormat: '3200',
  },
  {
    Country: 'Turkey',
    ISO: 'TR',
    NationalTerm: 'Posta kodu',
    Regex: /^\d{5}$/,
    ReportingFormat: '34000',
  },
  {
    Country: 'Trinidad and Tobago',
    ISO: 'TT',
    NationalTerm: '-',
    Regex: /^\d{6}$/,
    ReportingFormat: '120110',
  },
  {
    Country: 'Taiwan (Province of China)',
    ISO: 'TW',
    NationalTerm: '郵遞區號',
    Regex: /^(\d{3}\-\d{3})\b|\b(\d{3}[-]\d{2})\b|\b(\d{6})\b|\b(\d{3})$/, // eslint-disable-line
    ReportingFormat: '237-01, 407, 999999, 999(-)999',
  },
  {
    Country: 'Tanzania, United Republic of',
    ISO: 'TZ',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '31324',
  },
  {
    Country: 'Ukraine',
    ISO: 'UA',
    NationalTerm: 'Поштовий індекс',
    Regex: /^\d{5}$/,
    ReportingFormat: '65000',
  },
  {
    Country: 'United States of America',
    ISO: 'US',
    AdditionalTerritories: 'Puerto Rico',
    NationalTerm: 'Zip code (US)',
    Regex: /^\d{5}(-\d{4})?$/,
    ReportingFormat: '11550, 11550-9999, 00716, 00716-9999',
  },
  {
    Country: 'Uruguay',
    ISO: 'UY',
    NationalTerm: 'Código postal',
    Regex: /^[1-9]\d{4}$/,
    ReportingFormat: '11700',
  },
  {
    Country: 'Uzbekistan',
    ISO: 'UZ',
    NationalTerm: 'Почтовый индекс',
    Regex: /^\d{6}$/,
    ReportingFormat: '702100',
  },
  {
    Country: 'Holy See',
    ISO: 'VA',
    NationalTerm: 'CAP (IT)',
    Regex: /^120$/,
    ReportingFormat: '120',
  },
  {
    Country: 'Saint Vincent and the Grenadines',
    ISO: 'VC',
    NationalTerm: 'Postal code',
    Regex: /^(VC)(\d{4})$/,
    ReportingFormat: 'VC0100',
  },
  {
    Country: 'Venezuela (Bolivarian Republic of)',
    ISO: 'VE',
    NationalTerm: 'Código postal',
    Regex: /^[1-8]\d{3}$/,
    ReportingFormat: '1061',
  },
  {
    Country: 'Virgin Islands (British)',
    ISO: 'VG',
    NationalTerm: 'Postal code',
    Regex: /^(VG11)[0-6][0]$/,
    ReportingFormat: 'VG1120',
  },
  {
    Country: 'Virgin Islands (U.S.)',
    ISO: 'VI',
    NationalTerm: 'Zip code (US)',
    Regex: /^008\d{2}(-\d{4})?$/,
    ReportingFormat: '00850, 00850-9999',
  },
  {
    Country: 'Viet Nam',
    ISO: 'VN',
    NationalTerm: 'Mã bưu chính',
    Regex: /^\d{6}$/,
    ReportingFormat: '112132',
  },
  {
    Country: 'Wallis and Futuna',
    ISO: 'WF',
    NationalTerm: 'Code postal (FR)',
    Regex: /^(986)\d{2}$/,
    ReportingFormat: '98600',
  },
  {
    Country: 'Samoa',
    ISO: 'WS',
    NationalTerm: 'Postal code',
    Regex: /^WS[1-2]\d{3}$/,
    ReportingFormat: 'WS1382',
  },
  {
    Country: 'South Africa',
    ISO: 'ZA',
    NationalTerm: 'Postal code',
    Regex: /^\d{4}$/,
    ReportingFormat: '6001',
  },
  {
    Country: 'Zambia',
    ISO: 'ZM',
    NationalTerm: 'Postal code',
    Regex: /^\d{5}$/,
    ReportingFormat: '50100',
  },
]
