import {type ComponentProps} from 'react'
import {useInstance} from 'react-ioc'
import Icon from '@ant-design/icons'
import {observer} from 'mobx-react-lite'
import CloseIconSvg from 'assets/images/icons/close-icon.svg?react'
import MoonIconSvg from 'assets/images/icons/moon.svg?react'
import OpenIconSvg from 'assets/images/icons/open-icon.svg?react'
import SelectGiftIconDarkSvg from 'assets/images/icons/select-icn-gift-dark.svg?react'
import SelectGiftIconSvg from 'assets/images/icons/select-icn-gift.svg?react'
import SelectHeartIconDarkSvg from 'assets/images/icons/select-icn-heart-dark.svg?react'
import SelectHeartIconSvg from 'assets/images/icons/select-icn-heart.svg?react'
import SelectSecureIconDarkSvg from 'assets/images/icons/select-icn-secure-dark.svg?react'
import SelectSecureIconSvg from 'assets/images/icons/select-icn-secure.svg?react'
import SunIconSvg from 'assets/images/icons/sun.svg?react'
import {ThemeStore} from 'new/stores/ThemeStore'

///////////////////////////////////////////////////////////////////////////////

type IconComponentProps = ComponentProps<typeof Icon>

///////////////////////////////////////////////////////////////////////////////

export const CloseIcon = observer((props: IconComponentProps) => (
  <Icon component={CloseIconSvg} {...props} />
))

///////////////////////////////////////////////////////////////////////////////

export const OpenIcon = observer((props: IconComponentProps) => (
  <Icon component={OpenIconSvg} {...props} />
))

///////////////////////////////////////////////////////////////////////////////

export const SelectHeartIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? SelectHeartIconDarkSvg : SelectHeartIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const SelectSecureIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? SelectSecureIconDarkSvg : SelectSecureIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const SelectGiftIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? SelectGiftIconDarkSvg : SelectGiftIconSvg
  return <Icon component={icon} {...props} />
})

///////////////////////////////////////////////////////////////////////////////

export const ToggleThemeIcon = observer((props: IconComponentProps) => {
  const {isDarkTheme} = useInstance(ThemeStore)
  const icon = isDarkTheme ? SunIconSvg : MoonIconSvg
  return <Icon component={icon} {...props} />
})
