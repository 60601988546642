import {type FactoryOpts} from 'imask'
import {get} from 'lodash-es'
import isIBAN from 'validator/lib/isIBAN'
import {CountryCode} from 'types/CountryCode'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type IbanOptions = {
  qr_iban?: boolean
}

///////////////////////////////////////////////////////////////////////////////

export const IBAN_MASK_OPTIONS: FactoryOpts = {
  mask: 'aa00 #### #### #### #### #### #### #### ##',
  definitions: {
    '#': /[a-zA-Z0-9]/,
  },
}

///////////////////////////////////////////////////////////////////////////////

// CH IBAN: CH9300762011623852957
// CH QR IBAN: CH9230045650125127833
// More details: https://www.paymentstandards.ch/dam/downloads/qr-iid_qr-iban-en.pdf

export const iban: ValidationRule<IbanOptions> = ({field, data, options}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  if (!isIBAN(value)) {
    return {message: 'iban.not_valid'}
  }

  let {qr_iban} = options ?? {}

  // Allowed by default
  if (qr_iban === undefined) {
    qr_iban = true
  }

  if (!qr_iban) {
    const strippedValue = value.replace(/[\s-]+/g, '').toUpperCase()
    const countryCode = strippedValue.substring(0, 2)
    const iid = parseInt(strippedValue.substring(4, 9))

    if (
      [CountryCode.CH, CountryCode.LI].includes(countryCode as CountryCode) &&
      iid >= 30000 &&
      iid <= 31999
    ) {
      return {message: 'iban.qr_iban'}
    }
  }
}
