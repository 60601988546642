import {ctx} from 'new/ctx'
import {type IStorageService} from 'types/IStorageService'

////////////////////////////////////////////////////////////////////////////////

export class LocalStorageService implements IStorageService {
  constructor() {
    ctx.localStorageService = this
  }

  get<T = any>(key: string): T | undefined {
    const rawValue = localStorage.getItem(key)

    if (rawValue === null) {
      return undefined
    }

    try {
      return JSON.parse(rawValue) as T
    } catch (error) {
      return undefined
    }
  }

  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  remove(key: string) {
    localStorage.removeItem(key)
  }

  clear() {
    localStorage.clear()
  }
}
