import chroma from 'chroma-js'
import {get} from 'lodash-es'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type ColorContrastRatioOptions = {
  minimum: number
  color: string
  type: string
}

///////////////////////////////////////////////////////////////////////////////

export const colorContrastRatio: ValidationRule<ColorContrastRatioOptions> = ({
  field,
  data,
  options,
}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  const isValid = chroma.valid(value)

  if (!isValid) {
    return
  }

  const {minimum = 4.5, color = 'white', type = 'warning'} = options ?? {}
  const ratio = chroma.contrast(value, color)

  if (ratio < minimum) {
    return {message: `color_contrast_ratio.${type}`}
  }
}
