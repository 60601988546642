import {merge} from 'lodash-es'
import {makeAutoObservable} from 'mobx'
import {ctx} from 'new/ctx'

///////////////////////////////////////////////////////////////////////////////

export type AppConfig = typeof data

// prettier-ignore
const data = {
  language: import.meta.env.VITE_LANGUAGE,
  fallback_language: import.meta.env.VITE_FALLBACK_LANGUAGE,
  self_base_url: import.meta.env.VITE_SELF_BASE_URL,
  api_base_url: import.meta.env.VITE_API_BASE_URL,
  auth_base_url: import.meta.env.VITE_AUTH_BASE_URL,
  connect_base_url: import.meta.env.VITE_CONNECT_BASE_URL,
  oauth_client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
  tp_api_base_url: import.meta.env.VITE_TP_API_BASE_URL,
  widget_api_base_url: import.meta.env.VITE_PUBLIC_WIDGET_API_URL,
  recaptcha_key: import.meta.env.VITE_RECAPTCHA_KEY,
  configuration_uuid: import.meta.env.VITE_CONFIGURATION_UUID,
  twint_qr_tamaro_widget_url: import.meta.env.VITE_TWINT_QR_TAMARO_WIDGET_URL,
  lettershop_tamaro_iframe_url: import.meta.env.VITE_LETTERSHOP_TAMARO_IFRAME_URL,
  twint_qr_tamaro_widget_logo_base_url: import.meta.env.VITE_TWINT_QR_TAMARO_WIDGET_LOGO_BASE_URL,
  paylink_pay_base_url: import.meta.env.VITE_PAYLINK_PAY_BASE_URL,
  paylink_donate_base_url: import.meta.env.VITE_PAYLINK_DONATE_BASE_URL,
  terminal_link_base_url: import.meta.env.VITE_TERMINAL_LINK_BASE_URL,
  paylink_button_base_url: import.meta.env.VITE_PAYLINK_BUTTON_BASE_URL,
  donation_receipt_base_url: import.meta.env.VITE_DONATION_RECEIPT_BASE_URL,
  donation_receipt_s3_folder: import.meta.env.VITE_DONATION_RECEIPT_S3_FOLDER,
  stripe_dashboard_payments_url: import.meta.env.VITE_STRIPE_DASHBOARD_PAYMENTS_URL,
  sign_up_link_private_key: import.meta.env.VITE_SIGN_UP_LINK_PRIVATE_KEY!,
}

///////////////////////////////////////////////////////////////////////////////

export class AppConfigStore {
  // state
  data = data

  constructor() {
    makeAutoObservable(this)

    ctx.appConfigStore = this
  }

  update(data: Partial<AppConfig>) {
    merge(this.data, data)
  }
}
