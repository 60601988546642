import {type FC} from 'react'
import {useTranslation} from 'react-i18next'
import {useInstance} from 'react-ioc'
import {Button, Popover, Typography} from 'antd'
import cn from 'classnames'
import {type TFunction} from 'i18next'
import {observer} from 'mobx-react-lite'
import {getEnumTranslations} from 'lib/helpers/getEnumTranslations'
import {getOptionsTranslations} from 'lib/helpers/getOptionsTranslations'
import {useAppConfig} from 'lib/hooks/useAppConfig'
import {useNamespace} from 'lib/hooks/useNamespace'
import {useWideViewportWidth} from 'lib/hooks/useWideViewportWidth'
import {Select} from 'new/form/Select'
import {Text} from 'new/form/Text'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {FormOnboardingStore} from 'new/stores/FormOnboardingStore'
import {AnalyticsEventName} from 'types/AnalyticsEventName'
import {CountryCode} from 'types/CountryCode'
import pageStyles from 'assets/styles/modules/auth-page.module.scss'

///////////////////////////////////////////////////////////////////////////////

const {Title} = Typography

export enum SignupUrlParameters {
  ORGANISATION_DATA = 'organisation_data',
}

///////////////////////////////////////////////////////////////////////////////

const getOptions = (t: TFunction) => {
  const organisationTypes = getOptionsTranslations(t, {
    name: 'options.organisation_types',
  })
  const countries = getEnumTranslations(t, CountryCode, 'options.countries')

  return {organisationTypes, countries}
}

///////////////////////////////////////////////////////////////////////////////

const StepOrganisation: FC = observer(() => {
  const {ns} = useNamespace()
  const {t} = useTranslation(ns)
  const analyticsEventService = useInstance(AnalyticsEventService)
  const {isSending, form} = useInstance(FormOnboardingStore)
  const {validationErrors} = form
  const {organisationTypes, countries} = getOptions(t)
  const {auth_base_url} = useAppConfig()

  const title = t('organisation.title')
  const buttonContinue = t('organisation.buttons.continue')
  const linkSignIn = t('organisation.links.sign_in')
  const infoTitle = t('organisation.info.title')
  const infoDescription = t('organisation.info.description')

  const isNameTooLong = validationErrors?.['organisation.name']
    ? validationErrors['organisation.name'].filter(e =>
        e.message.includes('too_long')
      ).length > 0
    : false

  const isViewportWide = useWideViewportWidth()

  return (
    <div>
      <Title level={2} className={pageStyles.title}>
        {title}
      </Title>

      <div className={pageStyles.formCont}>
        <Popover
          placement={!isViewportWide ? 'bottom' : 'right'}
          title={infoTitle}
          open={isNameTooLong}
          content={
            <div data-cy="signup.orgNameTooLong" className={pageStyles.popOver}>
              {infoDescription}
            </div>
          }
          overlayClassName={cn({[pageStyles.popoverCont]: isViewportWide})}
        >
          <Text name="organisation.name" autoComplete="organization" />
        </Popover>

        <Select
          name="organisation.merchant_category_code"
          placeholder={t('organisation.select')}
          options={organisationTypes}
          popupClassName="organisation-type-menu"
        />

        <Select
          name="organisation.country_code"
          options={countries}
          allowClear
          showSearch
        />

        <Text name="organisation.website_url" autoComplete="url" />

        <div className={pageStyles.buttonsCont}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            onClick={() =>
              analyticsEventService.emit(
                AnalyticsEventName.SIGN_UP_ORGANISATION_CONTINUE_BUTTON_CLICK
              )
            }
            loading={isSending}
            data-cy="signUp.button.continue"
          >
            {buttonContinue}
          </Button>
        </div>

        <div className={pageStyles.formFooter}>
          <div className={pageStyles.formFooterLink}>
            <a href={`${auth_base_url}/login`}>{linkSignIn}</a>
          </div>
        </div>
      </div>
    </div>
  )
})

export default StepOrganisation
