import {type FC} from 'react'
import {Modal as AntModal} from 'antd'
import {type ModalProps} from 'antd/lib/modal'
import {observer} from 'mobx-react-lite'
import {CloseIcon} from 'components/Icons'

///////////////////////////////////////////////////////////////////////////////

type Props = ModalProps

///////////////////////////////////////////////////////////////////////////////

export const Modal: FC<Props> = observer(props => {
  const {children, ...rest} = props

  return (
    <AntModal
      centered
      destroyOnClose
      footer={null}
      closeIcon={<CloseIcon />}
      width="100%"
      {...rest}
    >
      {children}
    </AntModal>
  )
})
