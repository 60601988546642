import {type TFunction} from 'i18next'
import {each, get, isPlainObject, isString} from 'lodash-es'
import {type OptionItem} from 'types/OptionItem'
import mappings from 'config/mappings.yml'

///////////////////////////////////////////////////////////////////////////////

export type GetOptionsTranslationsOptions = {
  name: string
}

export type OptionTranslation =
  | string
  | {
      label?: string
      description_html?: string
    }

///////////////////////////////////////////////////////////////////////////////

export const getOptionsTranslations = (
  t: TFunction,
  options: GetOptionsTranslationsOptions
): OptionItem[] => {
  const {name} = options
  const values: string[] = get(mappings, name)
  const optionItems: OptionItem[] = []

  each(values, v => {
    const translation = t(`${name}.${v}`, {
      returnObjects: true,
    }) as OptionTranslation
    let optionItem: OptionItem | null = null

    if (isString(translation)) {
      optionItem = {
        value: v,
        label: translation,
      }
    } else if (isPlainObject(translation)) {
      optionItem = {value: v}

      if (translation.label) {
        optionItem.label = translation.label
      }

      if (translation.description_html) {
        optionItem.descriptionHTML = translation.description_html
      }
    }

    optionItems.push(optionItem!)
  })

  return optionItems
}
