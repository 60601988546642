import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import {tryParseJSON} from './tryParseJSON'

/**
 * www.npmjs.com/package/crypto-js
 * takes a string or an object and a private key and turns is it into encrypted string
 * which can be decrypted later withe the help of the same private key
 *
 * @example
 *
 * const ordgDataRaw = {hubspotId: '20855984498', name: 'TEST_Alhubmi_3'}
 *
 * encrypt(orgDataRaw, sign_up_link_private_key) // U2FsdGVkX186uymVrGH4GfzEXXU3eNEudhlkd4hDm96RUQy9ipgaKxZAkFaX8Afs3j8Q+W+eUZgpeQRnhwoHimzoxUU/jP5wqoBxSnNFPEI=
 *
 */

export const encrypt = (content: any, password: any) =>
  encodeURI(AES.encrypt(JSON.stringify({content}), password).toString())

export const decrypt = (cryptedString: string, password: string) => {
  const decodedURL = decodeURI(cryptedString)
  const decryptedString = AES.decrypt(decodedURL, password)

  return tryParseJSON(decryptedString.toString(Utf8)).content
}
