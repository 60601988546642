import {get} from 'lodash-es'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export const checked: ValidationRule = ({field, data}) => {
  const value = get<boolean | undefined>(data, field, false) ?? false

  if (!value) {
    return {message: 'checked'}
  }
}
