import {inject} from 'react-ioc'
import axios, {type AxiosError} from 'axios'
import {makeAutoObservable} from 'mobx'
import {ctx} from 'new/ctx'
import {AppConfigStore} from 'new/stores/AppConfigStore'
import {SessionStore} from 'new/stores/SessionStore'

///////////////////////////////////////////////////////////////////////////////

export class ApiStore {
  #appConfigStore = inject<AppConfigStore>(this, AppConfigStore)
  #sessionStore = inject<SessionStore>(this, SessionStore)

  constructor() {
    makeAutoObservable(this)

    ctx.apiStore = this
  }

  get api() {
    const {api_base_url} = this.#appConfigStore.data

    const api = axios.create({
      baseURL: api_base_url,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    api.interceptors.request.use(requestConfig => {
      const {token} = this.#sessionStore.getTokenInfo()

      if (token) {
        requestConfig.headers.Authorization = `Bearer ${token}`
      }

      return requestConfig
    })

    api.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status === 401) {
          this.#sessionStore.removeSession()
        }

        return Promise.reject(error as AxiosError)
      }
    )

    return api
  }

  get = this.api.get.bind(this)
  post = this.api.post.bind(this)
  patch = this.api.patch.bind(this)
  delete = this.api.delete.bind(this)
  request = this.api.request.bind(this)
}
