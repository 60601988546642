/**
 * Headers should be sorted in the following order:
 * 1. Non-parameters first, in the order they appear from the formatter
 * 2. raisenow_parameters
 * 3. custom_parameters
 * 4. supporter.custom_parameters
 */
export const sortExportHeaders = (headers: string[]) => {
  const nonRaisenowCustom = headers.filter(
    item =>
      !item.startsWith('raisenow_parameters') &&
      !item.startsWith('custom_parameters') &&
      !item.startsWith('supporter.custom_parameters')
  )
  const raisenowParamters = headers.filter(item =>
    item.startsWith('raisenow_parameters')
  )
  const customParameters = headers.filter(item =>
    item.startsWith('custom_parameters')
  )
  const supporterCustomParameters = headers.filter(item =>
    item.startsWith('supporter.custom_parameters')
  )

  const sortedArray = [
    ...nonRaisenowCustom,
    ...raisenowParamters,
    ...customParameters,
    ...supporterCustomParameters,
  ]

  return sortedArray
}
