import {createContextProvider} from 'lib/helpers/createContextProvider'
import type {TableHandler} from 'new/services/TableHandler'

////////////////////////////////////////////////////////////////////////////////

type TableHandlerProvider = {
  tableHandler: TableHandler
}

////////////////////////////////////////////////////////////////////////////////

export const [TableHandlerProvider, useTableHandler] = createContextProvider(
  (props: TableHandlerProvider) => {
    const {tableHandler} = props

    return tableHandler
  }
)
