import {delay} from 'lib/helpers/delay'

/**
 *
 * This generator function helps to infer the type of yield expression based on yielded value.
 * Source: https://github.com/mobxjs/mobx/discussions/3195
 * Open TS issue: https://github.com/microsoft/TypeScript/issues/32523
 *
 * Example:
 *
 * ```ts
 * // Asyc function, which returns Promise<number>
 * const getDataAsync = (input: string): Promise<number> => { ... }
 *
 * // MobX "flow" method (generator function)
 * *getData() {
 *   // data is typed as number
 *   const data = yield* toGenerator(getDataAsync('some input'))
 * }
 * ```
 */
export function* toGenerator<T>(
  promise: Promise<T>
): Generator<Promise<T>, T, T> {
  return yield promise
}

/**
 * Creates a notification trigger object.
 * Calling `show` method will set `isShown` to `true`,
 * then yield, and then set `isShown` to `false`.
 * This will force component to be re-rendered,
 * so if we use `isShown` in a component in a `useEffect` dependency array,
 * it will trigger the effect.
 */
export const createNotificationTrigger = () => {
  return {
    isShown: false,
    *show() {
      this.isShown = true
      yield delay(100)
      this.isShown = false
    },
  }
}
