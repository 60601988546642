import {type OnboardingApiService} from 'new/services/OnboardingApiService'
import {type AnalyticsEventService} from './services/analytics/AnalyticsEventService'
import {type GAService} from './services/analytics/GAService'
import {type InstanaService} from './services/analytics/InstanaService'
import {type CapabilitiesApiService} from './services/CapabilitiesApiService'
import {type CapabilityProfilesApiService} from './services/CapabilityProfilesApiService'
import {type FeatureFlagsApiService} from './services/FeatureFlagsApiService'
import {type FeesApiService} from './services/FeesApiService'
import {type I18nService} from './services/I18nService'
import {type LedgerBalanceApiService} from './services/LedgerBalanceApiService'
import {type LedgerRecordsApiService} from './services/LedgerRecordsApiService'
import {type LocalStorageService} from './services/LocalStorageService'
import {type MfaApiService} from './services/MfaApiService'
import {type OrganisationAddressesApiService} from './services/OrganisationAddressesApiService'
import {type OrganisationFilesApiService} from './services/OrganisationFilesApiService'
import {type OrganisationMetadataApiService} from './services/OrganisationMetadataApiService'
import {type OrganisationsApiService} from './services/OrganisationsApiService'
import {type PaymentSourcesApiService} from './services/PaymentSourcesApiService'
import {type PayoutsApiService} from './services/PayoutsApiService'
import {type PlanAssignmentsApiService} from './services/PlanAssignmentsApiService'
import {type SolutionFilesApiService} from './services/SolutionFilesApiService'
import {type SolutionsApiService} from './services/SolutionsApiService'
import {type SolutionServicesApiService} from './services/SolutionServicesApiService'
import {type SolutionsTwintQrApiService} from './services/SolutionsTwintQrApiService'
import {type SubscriptionsApiService} from './services/SubscriptionsApiService'
import {type SupporterApiService} from './services/SupporterApiService'
import {type TransactionCurrenciesApiService} from './services/TransactionCurrenciesApiService'
import {type TransactionsApiService} from './services/TransactionsApiService'
import {type TransactionsStatsApiService} from './services/TransactionsStatsApiService'
import {type UsersApiService} from './services/UsersApiService'
import {type ApiStore} from './stores/ApiStore'
import {type AppConfigStore} from './stores/AppConfigStore'
import {type CookieConsentStore} from './stores/CookieConsentStore'
import {type CurrentOrganisationAddressesStore} from './stores/CurrentOrganisationAddressesStore'
import {type CurrentOrganisationCapabilitiesStore} from './stores/CurrentOrganisationCapabilitiesStore'
import {type CurrentOrganisationCapabilityProfilesStore} from './stores/CurrentOrganisationCapabilityProfilesStore'
import {type CurrentOrganisationFilesStore} from './stores/CurrentOrganisationFilesStore'
import {type CurrentOrganisationFileUploadStore} from './stores/CurrentOrganisationFileUploadStore'
import {type CurrentOrganisationMetadataStore} from './stores/CurrentOrganisationMetadataStore'
import {type CurrentOrganisationPlanAssignmentsStore} from './stores/CurrentOrganisationPlanAssignmentsStore'
import {type CurrentOrganisationStore} from './stores/CurrentOrganisationStore'
import {type CurrentOrganisationUsersStore} from './stores/CurrentOrganisationUsersStore'
import {type CurrentUserStore} from './stores/CurrentUserStore'
import {type FeatureFlagsStore} from './stores/FeatureFlagsStore'
import {type FeesStore} from './stores/FeesStore'
import {type FormChangePasswordStore} from './stores/FormChangePasswordStore'
import {type FormEditSubscription} from './stores/FormEditSubscription'
import {type FormEmailAdvancedOptionsStore} from './stores/FormEmailAdvancedOptionsStore'
import {type FormExportStore} from './stores/FormExport'
import {type FormGenericPaylinkButton} from './stores/FormGenericPaylinkButton'
import {type FormLettershopOrderChangeAddressStore} from './stores/FormLettershopOrderChangeAddressStore'
import {type FormLettershopOrderStore} from './stores/FormLettershopOrderStore'
import {type FormMfaStore} from './stores/FormMfaStore'
import {type FormOnboardingStore} from './stores/FormOnboardingStore'
import {type FormRefundStore} from './stores/FormRefundStore'
import {type FormSettingsOrganisationStore} from './stores/FormSettingsOrganisationStore'
import {type FormSettingsOrganisationUserStore} from './stores/FormSettingsOrganisationUserStore'
import {type FormSettingsProfileUserStore} from './stores/FormSettingsProfileUserStore'
import {type FormSolutionConfigStore} from './stores/FormSolutionConfigStore'
import {type LedgerBalanceStore} from './stores/LedgerBalanceStore'
import {type PaymentProvidersStore} from './stores/PaymentProvidersStore'
import {type PayoutItemStore} from './stores/PayoutItemStore'
import {type PayoutLedgerRecordsStore} from './stores/PayoutLedgerRecordsStore'
import {type PayoutsStore} from './stores/PayoutsStore'
import {type PayoutTransactionsStore} from './stores/PayoutTransactionsStore'
import {type RouterStore} from './stores/RouterStore'
import {type SessionStore} from './stores/SessionStore'
import {type SolutionFileUploadStore} from './stores/SolutionFileUploadStore'
import {type SolutionItemStore} from './stores/SolutionItemStore'
import {type SolutionServicesStore} from './stores/SolutionServicesStore'
import {type SolutionsStore} from './stores/SolutionsStore'
import {type SubscriptionInvoiceItemStore} from './stores/SubscriptionInvoiceItemStore'
import {type SubscriptionInvoicesStore} from './stores/SubscriptionInvoicesStore'
import {type SubscriptionItemStore} from './stores/SubscriptionItemStore'
import {type SubscriptionsStore} from './stores/SubscriptionsStore'
import {type ThemeStore} from './stores/ThemeStore'
import {type TransactionCurrenciesStore} from './stores/TransactionCurrenciesStore'
import {type TransactionItemStore} from './stores/TransactionItemStore'
import {type TransactionsStatsStore} from './stores/TransactionsStatsStore'
import {type TransactionsStore} from './stores/TransactionsStore'
import {type UiStore} from './stores/UiStore'
import {type ZendeskWidgetStore} from './stores/ZendeskWidgetStore'

///////////////////////////////////////////////////////////////////////////////

export type Ctx = Partial<{
  // services
  analyticsEventService: AnalyticsEventService
  capabilitiesApiService: CapabilitiesApiService
  capabilityProfilesApiService: CapabilityProfilesApiService
  featureFlagsApiService: FeatureFlagsApiService
  feesApiService: FeesApiService
  gaService: GAService
  i18nService: I18nService
  instanaService: InstanaService
  ledgerBalanceApiService: LedgerBalanceApiService
  ledgerRecordsApiService: LedgerRecordsApiService
  localStorageService: LocalStorageService
  mfaApiService: MfaApiService
  onboardingApiService: OnboardingApiService
  organisationAddressesApiService: OrganisationAddressesApiService
  organisationFilesApiService: OrganisationFilesApiService
  organisationMetadataApiService: OrganisationMetadataApiService
  organisationsApiService: OrganisationsApiService
  paymentSourcesApiService: PaymentSourcesApiService
  payoutsApiService: PayoutsApiService
  planAssignmentsApiService: PlanAssignmentsApiService
  solutionFilesApiService: SolutionFilesApiService
  solutionsApiService: SolutionsApiService
  solutionServicesApiService: SolutionServicesApiService
  solutionsTwintQrApiService: SolutionsTwintQrApiService
  subscriptionsApiService: SubscriptionsApiService
  transactionCurrenciesApiService: TransactionCurrenciesApiService
  transactionsApiService: TransactionsApiService
  transactionsStatsApiService: TransactionsStatsApiService
  usersApiService: UsersApiService
  supporterApiService: SupporterApiService

  // stores
  apiStore: ApiStore
  appConfigStore: AppConfigStore
  cookieConsentStore: CookieConsentStore
  currentOrganisationAddressesStore: CurrentOrganisationAddressesStore
  currentOrganisationCapabilitiesStore: CurrentOrganisationCapabilitiesStore
  currentOrganisationCapabilityProfilesStore: CurrentOrganisationCapabilityProfilesStore
  currentOrganisationFilesStore: CurrentOrganisationFilesStore
  currentOrganisationFileUploadStore: CurrentOrganisationFileUploadStore
  currentOrganisationMetadataStore: CurrentOrganisationMetadataStore
  currentOrganisationPlanAssignmentsStore: CurrentOrganisationPlanAssignmentsStore
  currentOrganisationStore: CurrentOrganisationStore
  currentOrganisationUsersStore: CurrentOrganisationUsersStore
  currentUserStore: CurrentUserStore
  featureFlagsStore: FeatureFlagsStore
  feesStore: FeesStore
  ledgerBalanceStore: LedgerBalanceStore
  paymentProvidersStore: PaymentProvidersStore
  payoutItemStore: PayoutItemStore
  payoutLedgerRecordsStore: PayoutLedgerRecordsStore
  payoutTransactionsStore: PayoutTransactionsStore
  payoutsStore: PayoutsStore
  routerStore: RouterStore
  sessionStore: SessionStore
  solutionItemStore: SolutionItemStore
  solutionServicesStore: SolutionServicesStore
  solutionFileUploadStore_Info: SolutionFileUploadStore
  solutionFileUploadStore_Logo: SolutionFileUploadStore
  solutionsStore: SolutionsStore
  subscriptionInvoiceItemStore: SubscriptionInvoiceItemStore
  subscriptionInvoicesStore: SubscriptionInvoicesStore
  subscriptionItemStore: SubscriptionItemStore
  subscriptionsStore: SubscriptionsStore
  themeStore: ThemeStore
  transactionCurrenciesStore: TransactionCurrenciesStore
  transactionItemStore: TransactionItemStore
  transactionsStatsStore: TransactionsStatsStore
  transactionsStore: TransactionsStore
  uiStore: UiStore
  zendeskWidgetStore: ZendeskWidgetStore

  // forms
  formChangePasswordStore: FormChangePasswordStore
  formEditSubscription: FormEditSubscription
  formExportStore: FormExportStore
  formLettershopOrderChangeAddressStore: FormLettershopOrderChangeAddressStore
  formLettershopOrderStore: FormLettershopOrderStore
  formMfaStore: FormMfaStore
  formOnboardingStore: FormOnboardingStore
  formRefundStore: FormRefundStore
  formSettingsOrganisationStore: FormSettingsOrganisationStore
  formSettingsOrganisationUserStore: FormSettingsOrganisationUserStore
  formSettingsProfileUserStore: FormSettingsProfileUserStore
  formSolutionConfigStore: FormSolutionConfigStore
  formGenericPaylinkButton: FormGenericPaylinkButton
  formEmailAdvancedOptionsStore: FormEmailAdvancedOptionsStore
}>

///////////////////////////////////////////////////////////////////////////////

export const ctx: Ctx = {}

//
;(window as any).ctx = ctx
