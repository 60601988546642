export const PENDING_EMAIL_ADDRESS = 'pending_email_address'
export const SHOW_WELCOME_MODAL = 'show_welcome_modal'
export const SHOW_PRIVACY_POLICY_NOTIFICATION =
  'show_privacy_policy_notification'
export const ORGANISATION_NOT_APPROVED_NOTIFICATION_SHOWN =
  'organisation_not_approved_notification_shown'
export const SHOW_SUPPORT_CLUBS_MODAL = 'show_support_clubs_modal'
export const SHOW_WELCOME_DASHBOARD_MODAL = 'show_welcome_dashboard_modal'
export const SHOW_WELCOME_SOLUTIONS_MODAL = 'show_welcome_solutions_modal'
export const SHOW_EMAIL_ADVANCED_OPTIONS_MODAL =
  'show_email_advanced_options_modal'
export const STRIPE_STATE_PARAM = 'stripe_state_param'
export const PAYPAL_REFERRAL_TOKEN = 'paypal_referral_token'
export const TOKEN = 'token'
export const UI = 'ui'
export const GENERIC_PAYLINK_BUTTON_CONFIG = 'generic_paylink_button_config'
