import {useEffect} from 'react'
import {useAsyncState} from './useAsyncState'

///////////////////////////////////////////////////////////////////////////////

export const useWideViewportWidth = (minWidth = 1024): boolean => {
  const [isWide, setIsWide] = useAsyncState(false)

  const handleResize = () => {
    setIsWide(window.innerWidth >= minWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isWide
}
