import {get, isNumber} from 'lodash-es'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type LengthOptions = {
  is?: number
  minimum?: number
  maximum?: number
}

///////////////////////////////////////////////////////////////////////////////

export const length: ValidationRule<LengthOptions> = ({
  field,
  data,
  options,
}) => {
  const value = get<string | undefined>(data, field, '') ?? ''
  const {is, minimum, maximum} = options ?? {}

  if (!value) {
    return
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length#strings_with_length_not_equal_to_the_number_of_characters
  const length = [...value].length

  if (!isNumber(length)) {
    return {message: 'length.wrong_length'}
  }

  if (isNumber(is) && length !== is) {
    return {message: 'length.not_valid', data: {is}}
  }

  if (isNumber(minimum) && length < minimum) {
    return {message: 'length.too_short', data: {minimum, maximum}}
  }

  if (isNumber(maximum) && length > maximum) {
    return {message: 'length.too_long', data: {minimum, maximum}}
  }
}
