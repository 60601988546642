export enum ApprovalStatus {
  NEW = 'new',
  APPROVAL_PENDING = 'approval_pending',
  APPROVED = 'approved',
}

export type Organisation = {
  uuid: string
  name: string
  created: number
  active: boolean
  approval_status: ApprovalStatus
  website_url: string
  merchant_category_code: MCC
  accounts: {uuid: string}[]
}

// Should be synced with organisation_types values in mappings.yml
export type MCC = '8699' | '7997' | '8398' | '8641' | '8651' | '8661' | '0000'

export enum Flow {
  SAVE = 'save',
  REQUEST_APPROVAL = 'request_approval',
}
