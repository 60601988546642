import {uniq} from 'lodash-es'

///////////////////////////////////////////////////////////////////////////////

export enum PasswordStrength {
  VERY_WEAK = 'very_weak',
  WEAK = 'weak',
  MEDIUM = 'medium',
  STRONG = 'strong',
}

///////////////////////////////////////////////////////////////////////////////

const getEntropy = (password: string) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length#strings_with_length_not_equal_to_the_number_of_characters
  const length = [...password].length
  const stringToUTF8Arr = Array.from(new TextEncoder().encode(password))
  const charsArr = uniq(stringToUTF8Arr)
  const chars = charsArr.length

  let control = 0,
    digit = 0,
    upper = 0,
    lower = 0,
    symbol = 0,
    other = 0

  for (const chr of charsArr) {
    switch (true) {
      case chr < 32 || chr === 127:
        control = 33
        break
      case chr >= 48 && chr <= 57:
        digit = 10
        break
      case chr >= 65 && chr <= 90:
        upper = 26
        break
      case chr >= 97 && chr <= 122:
        lower = 26
        break
      case chr >= 128:
        other = 128
        break
      default:
        symbol = 33
    }
  }

  const pool = lower + upper + digit + symbol + control + other
  const entropy = Math.floor(
    chars * Math.log2(pool) + (length - chars) * Math.log2(chars)
  )
  return entropy
}

///////////////////////////////////////////////////////////////////////////////

// Calculate [information entropy](https://en.wikipedia.org/wiki/Password_strength#Entropy_as_a_measure_of_password_strength)
/**
 * @param {String} str String to calculate entropy for
 * @returns {Number} entropy
 */
export const entropy = (str: string): number => {
  if (!str) {
    return 0
  }

  return getEntropy(str)
}

export const getPasswordStrength = (
  entropy: number
): PasswordStrength | undefined => {
  if (!entropy) {
    return
  }

  switch (true) {
    case entropy >= 100:
      return PasswordStrength.STRONG
    case entropy >= 80:
      return PasswordStrength.MEDIUM
    case entropy >= 60:
      return PasswordStrength.WEAK
  }

  return PasswordStrength.VERY_WEAK
}
