import {createContextProvider} from 'lib/helpers/createContextProvider'
import {type FormHandler} from 'new/services/FormHandler'

///////////////////////////////////////////////////////////////////////////////

export type FormHandlerProviderProps = {
  form: FormHandler<Record<string, any> | undefined>
}

///////////////////////////////////////////////////////////////////////////////

export const [FormHandlerProvider, useFormHandler] = createContextProvider(
  (props: FormHandlerProviderProps) => {
    const {form} = props

    return form
  }
)
