import {type FC} from 'react'
import {useInstance} from 'react-ioc'
import {Button} from 'antd'
import {observer} from 'mobx-react-lite'
import {ToggleThemeIcon} from 'components/Icons'
import {ThemeStore} from 'new/stores/ThemeStore'
import styles from './index.module.scss'

////////////////////////////////////////////////////////////////////////////////

export const ToggleThemeButton: FC = observer(() => {
  const {toggleTheme} = useInstance(ThemeStore)

  return (
    <Button
      className={styles.toggleThemeButton}
      type="link"
      icon={<ToggleThemeIcon />}
      onClick={toggleTheme}
    />
  )
})
