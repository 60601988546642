import {makeAutoObservable, toJS} from 'mobx'
import {ExportFormat} from 'components/Table/Export'
import {
  addValidationConstraint,
  type ValidationConstraints,
} from 'lib/validator'
import {ctx} from 'new/ctx'
import {FormHandler} from 'new/services/FormHandler'

///////////////////////////////////////////////////////////////////////////////

type FormExportValues = {
  export_format: ExportFormat
  include_custom_fields: boolean
}

///////////////////////////////////////////////////////////////////////////////

export class FormExportStore {
  // state
  values: FormExportValues = toJS(this.initialValues)
  form: FormHandler<FormExportValues>

  constructor() {
    makeAutoObservable(this)

    ctx.formExportStore = this

    this.form = new FormHandler({
      getInitialValues: () => toJS(this.initialValues),
      getValues: () => this.values,
      getValidationConstraints: () => this.validationConstraints,
    })
  }

  get initialValues(): FormExportValues {
    return {
      export_format: ExportFormat.XLSX,
      include_custom_fields: false,
    }
  }

  get validationConstraints() {
    const constraints: ValidationConstraints = {}

    addValidationConstraint(constraints, 'export_format', {
      presence: true,
    })

    return constraints
  }

  reset() {
    this.values = toJS(this.initialValues)
    this.form.touchedFields = []
  }
}
