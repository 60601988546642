import {type BackwardCompatibleMfaOption} from 'types/Mfa'

///////////////////////////////////////////////////////////////////////////////

export type User = {
  uuid: string
  username: string
  email: string
  enabled: boolean
  organisation_uuid: string
  first_name: string
  last_name: string
  locale: string
  timezone: string
  salutation: string
  created: number
  phone_number: string
  country_code: string
  auth_phone_number: string
  auth_phone_number_country_prefix: string
  two_factor_auth_choice: BackwardCompatibleMfaOption
  status: UserStatus
  invitation_expires_at: string
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  INVITE_EXPIRED = 'invite_expired',
}
