import {type BackendModule, type ReadCallback, type ResourceKey} from 'i18next'

///////////////////////////////////////////////////////////////////////////////

type I18nBackendOptions = {
  translationModuleResolvers: TranslationModuleResolvers
}

export type TranslationModuleResolvers = Record<
  string,
  () => Promise<TranslationModule>
>

export type TranslationModule = Record<string, any>

///////////////////////////////////////////////////////////////////////////////

export class I18nBackend implements BackendModule {
  public type = 'backend' as const
  public static type = 'backend' as const
  public options: I18nBackendOptions

  constructor(options: I18nBackendOptions) {
    this.options = options
  }

  init() {
    // do nothing
  }

  create() {
    // do nothing
  }

  async read(
    language: string,
    namespace: string,
    callback: ReadCallback
  ): Promise<void> {
    const {translationModuleResolvers} = this.options

    try {
      const data =
        await translationModuleResolvers[`../../translations/${language}.yml`]()

      callback(null, data[namespace])
    } catch (error) {
      if (error instanceof Error) {
        // eslint-disable-next-line no-console
        console.log(error)
        callback(error, false as unknown as ResourceKey)
      }
    }
  }
}
