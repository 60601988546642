import {isString} from 'lodash-es'
import zenscroll from 'zenscroll'
import {ERROR_MESSAGE_CLASS} from 'constants/css_class_names'
import {delay} from './delay'

///////////////////////////////////////////////////////////////////////////////

export type ScrollOptions = {
  offset?: number
  duration?: number
  timeout?: number
}

///////////////////////////////////////////////////////////////////////////////

export const scrollTo = (
  target: string | HTMLElement,
  options: ScrollOptions = {}
): Promise<void> => {
  return new Promise(resolve => {
    const {offset, duration, timeout} = getScrollOptions(options)

    setTimeout(() => {
      const el = isString(target)
        ? document.querySelector<HTMLElement>(target)
        : target

      if (el) {
        const scrollableEl = getScrollParent(el)
        let scroller
        let top

        if (scrollableEl === document.documentElement) {
          scroller = zenscroll
          top = getTop(el)
        } else {
          scroller = zenscroll.createScroller(scrollableEl, duration)
          top = getTop(el, scrollableEl)
        }

        scroller.toY(top - offset, duration, resolve)
      }
    }, timeout)
  })
}

///////////////////////////////////////////////////////////////////////////////

export const getTop = (
  el: HTMLElement,
  scrollableEl?: HTMLElement | null
): number => {
  if (scrollableEl) {
    return (
      el.getBoundingClientRect().top -
      scrollableEl.getBoundingClientRect().top +
      scrollableEl.scrollTop
    )
  } else {
    return el.getBoundingClientRect().top + getDocY()
  }
}

///////////////////////////////////////////////////////////////////////////////

export const getDocY = () => {
  return window.scrollY || document.documentElement.scrollTop
}

///////////////////////////////////////////////////////////////////////////////

export const getScrollParent = (el: HTMLElement): HTMLElement => {
  const regex = /(auto|scroll)/

  const getParents = (el: HTMLElement, ps: HTMLElement[]): HTMLElement[] => {
    if (el.parentNode === null) {
      return ps
    }

    return getParents(el.parentNode as HTMLElement, ps.concat([el]))
  }

  const getStyleProp = (el: HTMLElement, prop: string) => {
    return getComputedStyle(el, null).getPropertyValue(prop)
  }

  const isScrollable = (el: HTMLElement) => {
    const value = getStyleProp(el, 'overflow-y')

    return regex.test(value)
  }

  const scrollParent = (el: HTMLElement | SVGElement) => {
    if (!(el instanceof HTMLElement || el instanceof SVGElement)) {
      return document.scrollingElement ?? document.documentElement
    }

    const ps = getParents(el.parentNode as HTMLElement, [])

    for (const el of ps) {
      if (isScrollable(el)) {
        return el
      }
    }

    return document.scrollingElement ?? document.documentElement
  }

  return scrollParent(el) as HTMLElement
}

///////////////////////////////////////////////////////////////////////////////

export const getScrollOptions = (options: ScrollOptions = {}) => {
  let {offset, duration, timeout} = options

  if (offset === undefined) {
    offset = 0
  }

  if (duration === undefined) {
    duration = 300
  }

  if (timeout === undefined) {
    timeout = 0
  }

  return {offset, duration, timeout}
}

///////////////////////////////////////////////////////////////////////////////

export const getErrorMessageElements = (): Promise<HTMLElement[]> => {
  return new Promise(resolve => {
    setTimeout(() => {
      const errorMessageElements = [
        ...document.querySelectorAll<HTMLElement>('.' + ERROR_MESSAGE_CLASS),
      ]
      resolve(errorMessageElements)
    }, 0)
  })
}

///////////////////////////////////////////////////////////////////////////////

export const getTopErrorMessageElement = async (): Promise<
  HTMLElement | undefined
> => {
  return (await getErrorMessageElements())[0]
}

///////////////////////////////////////////////////////////////////////////////

export const getParentsBySelector = (
  el: HTMLElement,
  selector: string
): HTMLElement[] => {
  const parents: HTMLElement[] = []

  let parent = el.parentElement?.closest<HTMLElement>(selector)

  while (parent) {
    parents.push(parent)
    parent = parent.parentElement?.closest<HTMLElement>(selector)
  }

  return parents
}

///////////////////////////////////////////////////////////////////////////////

export const openCollapsedParents = async (el: HTMLElement) => {
  const parents = getParentsBySelector(el, '.ant-collapse-item')

  for (const parent of parents) {
    if (!parent.classList.contains('ant-collapse-item-active')) {
      parent.querySelector<HTMLElement>('.ant-collapse-header')?.click()
      await delay(250) // wait collapse animation to finish
    }
  }
}

///////////////////////////////////////////////////////////////////////////////

export const selectTabsParents = async (el: HTMLElement) => {
  const parents = getParentsBySelector(el, '.ant-tabs-tabpane')

  for (const parent of parents) {
    if (!parent.classList.contains('ant-tabs-tabpane-active')) {
      const labelledBy = parent.getAttribute('aria-labelledby')
      document.querySelector<HTMLElement>(`#${labelledBy}`)?.click()
      await delay(250) // wait tab animation to finish
    }
  }
}

///////////////////////////////////////////////////////////////////////////////

export const scrollToTopInvalidField = async (
  options: ScrollOptions = {}
): Promise<void> => {
  const newOptions = getScrollOptions(options)
  newOptions.offset = newOptions.offset + 64

  const fieldBlock = await getTopErrorMessageElement()

  fieldBlock && (await openCollapsedParents(fieldBlock))
  fieldBlock && (await selectTabsParents(fieldBlock))
  fieldBlock && (await scrollTo(fieldBlock, newOptions))
}

///////////////////////////////////////////////////////////////////////////////

export const focusTopInvalidField = async (): Promise<void> => {
  const fieldBlock = await getTopErrorMessageElement()
  const input = fieldBlock?.querySelector<HTMLElement>(
    'input, select, textarea, button'
  )

  fieldBlock && (await openCollapsedParents(fieldBlock))
  fieldBlock && (await selectTabsParents(fieldBlock))
  input?.focus()
}
