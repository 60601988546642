import dayjs, {type Dayjs} from 'dayjs'

// Format numbers for inline use. Do not use for tabular displays.
export const formatNumber = (
  amount: number,
  decimalPlaces = 0,
  tabularDisplay = false
) => {
  let sign = ''
  if (amount < 0) {
    amount = -amount
    sign = '\u2212' // typographic minus sign
  }
  const groupingThreshold = tabularDisplay ? 1e3 : 1e4
  const decimalThreshold = tabularDisplay ? 4 : 5
  const numberAsString = sign + amount.toFixed(decimalPlaces)
  if (amount < groupingThreshold && decimalPlaces < decimalThreshold) {
    return numberAsString
  }
  // Add digit groupings
  return groupDigits(numberAsString)
}

// Format money amounts for inline use. Do not use for tabular displays.
export const formatAmount = (amount: number) => formatNumber(amount, 2)

///////////////////////////////////////////////////////////////////////////////

export const formatTabularNumber = (amount: number, decimalPlaces = 0) =>
  formatNumber(amount, decimalPlaces, true)

export const formatTabularAmount = (amount: number) =>
  formatTabularNumber(amount, 2)

export const restoreAmount = (amount: string) =>
  parseFloat(amount.replace(/’/g, ''))

export const isValidDateFormat = (date?: number, format?: string) =>
  dayjs(date, format, true).isValid()

///////////////////////////////////////////////////////////////////////////////

export function formatTime(epoch: Dayjs) {
  return epoch.format(timeFormat)
}
export const dateFormat = 'DD.MM.YYYY'
export const timeFormat = dateFormat + ' HH:mm:ss'
export const monthRangePickerFormat = 'MMM YYYY'

///////////////////////////////////////////////////////////////////////////////
// Internals
///////////////////////////////////////////////////////////////////////////////

function groupDigits(number: string) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, '’')
}
