import {inject} from 'react-ioc'
import {ctx} from 'new/ctx'
import {ApiStore} from 'new/stores/ApiStore'
import {type Organisation} from 'types/Organisation'

///////////////////////////////////////////////////////////////////////////////

export class OrganisationsApiService {
  #apiStore = inject<ApiStore>(this, ApiStore)

  constructor() {
    ctx.organisationsApiService = this
  }

  async fetch(organisationUuid: string): Promise<Organisation> {
    const {data} = await this.#apiStore.get<any, {data: Organisation}>(
      `/organisations/${organisationUuid}`
    )

    return data
  }

  async save(data: Partial<Organisation>) {
    const {uuid, ...rest} = data
    return this.#apiStore.patch(`/organisations/${uuid}`, rest)
  }

  async requestApproval(organisationUuid: string) {
    return this.#apiStore.patch(
      `/organisations/${organisationUuid}/request-approval`
    )
  }
}
