import {inject} from 'react-ioc'
import {MAX_RESULTS} from 'components/Table/Export'
import {type TableQueryResult, type TableRequestParams} from 'models/table'
import {ctx} from 'new/ctx'
import {ApiStore} from 'new/stores/ApiStore'
import {SessionStore} from 'new/stores/SessionStore'
import {type ChangePasswordData} from 'types/ChangePasswordData'
import {type User, UserStatus} from 'types/User'

///////////////////////////////////////////////////////////////////////////////

export class UsersApiService {
  #sessionStore = inject<SessionStore>(this, SessionStore)
  #apiStore = inject<ApiStore>(this, ApiStore)

  constructor() {
    ctx.usersApiService = this
  }

  async fetchCurrentUser() {
    const {token} = this.#sessionStore.getTokenInfo()

    if (!token) {
      return undefined
    }

    const {data} = await this.#apiStore.get<any, {data: User}>('/users/myself')

    return data
  }

  async save(data: Partial<User>) {
    const {uuid, ...rest} = data

    return this.#apiStore.patch(`/users/${uuid}`, rest)
  }

  async fetchOrganisationUsers(params: TableRequestParams) {
    const {token} = this.#sessionStore.getTokenInfo()
    const request = buildRequest(params)

    if (!token) {
      return undefined
    }

    const {data} = await this.#apiStore.post<
      any,
      {data: TableQueryResult<Partial<User>>}
    >('/search/users', request)

    const hits = data.hits.map(user => {
      return {
        ...user,
        status:
          user.status === UserStatus.PENDING &&
          user.invitation_expires_at &&
          Math.floor(Date.now() / 1000) > Number(user.invitation_expires_at)
            ? UserStatus.INVITE_EXPIRED
            : user.status,
      }
    })

    return {...data, hits}
  }

  async inviteUsersToOrganisation(organisationUuid: string, emails: string[]) {
    return await this.#apiStore.post(
      `/users/invite-to-organisation/${organisationUuid}`,
      {emails}
    )
  }

  async resendInvite(data: Partial<User>) {
    const {uuid} = data

    return this.#apiStore.post(`/users/${uuid}/resend-invite`)
  }

  async revokeInvite(data: Partial<User>) {
    const {uuid} = data

    return this.#apiStore.post(`/users/${uuid}/revoke-invite`)
  }

  async removeUser(data: Partial<User>) {
    const {uuid} = data

    return this.#apiStore.delete(`/users/${uuid}`)
  }

  async changeEmail(email: string) {
    const data = {email}

    return this.#apiStore.post('/users/myself/request-change-email', data)
  }

  async changePassword(data: ChangePasswordData) {
    return this.#apiStore.post('/users/myself/change-password', data)
  }
}

///////////////////////////////////////////////////////////////////////////////
// Helpers
///////////////////////////////////////////////////////////////////////////////

const buildRequest = (params: TableRequestParams) => {
  let size = params.pagination.pageSize
  const from = size * (params.pagination.current - 1)

  if (size + from > MAX_RESULTS) {
    size = MAX_RESULTS - from
  }

  return {
    query: {
      $and: [
        {
          $term: {
            organisation_uuid: params.organisationUuid,
          },
        },
        {
          $not: [
            {
              $term: {
                status: 'inactive',
              },
            },
          ],
        },
      ],
    },
    size,
    from,
    sort: {
      ['status']: 'DESC',
      ['created']: 'DESC',
    },
  }
}
