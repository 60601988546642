import {inject} from 'react-ioc'
import {type ThemeConfig} from 'antd'
import {action, makeAutoObservable, reaction} from 'mobx'
import {UI_SIDEBAR_THEME, UI_THEME} from 'constants/cookie_entry_names'
import {ctx} from 'new/ctx'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {CookieStorageService} from 'new/services/CookieStorageService'
import {getDarkThemeConfig} from 'themes/DarkTheme'
import {getLightThemeConfig} from 'themes/LightTheme'
import {AnalyticsEventName} from 'types/AnalyticsEventName'

////////////////////////////////////////////////////////////////////////////////

export const SidebarTheme = {
  LIGHT: 'light',
  MIXED: 'mixed',
} as const

export type SidebarTheme = (typeof SidebarTheme)[keyof typeof SidebarTheme]

////////////////////////////////////////////////////////////////////////////////

export const Theme = {
  LIGHT: 'light',
  DARK: 'dark',
  AUTO: 'auto',
} as const

export type Theme = (typeof Theme)[keyof typeof Theme]

////////////////////////////////////////////////////////////////////////////////

export class ThemeStore {
  #analyticsEventService = inject(this, AnalyticsEventService)
  #cookieStorage = inject(this, CookieStorageService)
  #disposers: (() => void)[] = []

  // state

  userTheme?: Theme = this.#cookieStorage.get(UI_THEME) ?? Theme.LIGHT
  userSidebarTheme?: SidebarTheme =
    this.#cookieStorage.get(UI_SIDEBAR_THEME) ?? SidebarTheme.LIGHT
  systemTheme: Theme = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? Theme.DARK
    : Theme.LIGHT
  themeConfig?: ThemeConfig

  constructor() {
    ctx.themeStore = this
    makeAutoObservable(this)
    this.#initEffects()
  }

  get resolvedTheme() {
    if (this.userTheme === Theme.AUTO) {
      return this.systemTheme
    }

    return this.userTheme ?? Theme.LIGHT
  }

  get resolvedSidebarTheme(): SidebarTheme | typeof Theme.DARK {
    if (this.resolvedTheme === Theme.DARK) {
      return Theme.DARK
    }

    return this.userSidebarTheme ?? Theme.LIGHT
  }

  get isDarkTheme() {
    return this.resolvedTheme === Theme.DARK
  }

  get isMixedSidebarTheme() {
    return this.resolvedSidebarTheme === SidebarTheme.MIXED
  }

  setUserTheme(theme: Theme) {
    this.userTheme = theme
  }

  setUserSidebarTheme(sidebarTheme: SidebarTheme) {
    this.userSidebarTheme = sidebarTheme
  }

  #setThemeConfig() {
    /**
     * Helper functions `getLightTheme` and `getDarkTheme` collect properties
     * from CSS variables, which rely on "data-theme" attribute of the root element,
     * so we need to set it before calling these functions.
     */
    document.documentElement.setAttribute('data-theme', this.resolvedTheme)
    document.documentElement.setAttribute(
      'data-sidebar-theme',
      this.resolvedSidebarTheme
    )
    this.themeConfig = this.isDarkTheme
      ? getDarkThemeConfig()
      : getLightThemeConfig()
  }

  toggleTheme = () => {
    this.setUserTheme(this.isDarkTheme ? Theme.LIGHT : Theme.DARK)
  }

  #initEffects() {
    this.#disposers.push(
      reaction(
        () => [this.userTheme, this.userSidebarTheme, this.systemTheme],
        ([userTheme, userSidebarTheme]) => {
          this.#cookieStorage.set(UI_THEME, userTheme)
          this.#cookieStorage.set(UI_SIDEBAR_THEME, userSidebarTheme)
          this.#setThemeConfig()
        },
        {
          fireImmediately: true,
        }
      ),
      reaction(
        () => null,
        () => {
          // Subscribe for the system theme change.
          const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')
          darkThemeMq.onchange = action((e: any) => {
            const matches = e.matches
            this.systemTheme = matches ? Theme.DARK : Theme.LIGHT
          })
        },
        {fireImmediately: true}
      ),
      reaction(
        () => this.userTheme,
        theme => {
          let themeName

          if (theme === Theme.LIGHT) {
            themeName =
              this.resolvedSidebarTheme === this.resolvedTheme
                ? this.resolvedTheme
                : SidebarTheme.MIXED
          } else {
            themeName = theme
          }

          if (!themeName) {
            return
          }

          this.#analyticsEventService.emit(AnalyticsEventName.THEME_TOGGLE, {
            theme: themeName,
          })
        },
        {
          fireImmediately: false,
        }
      ),
      reaction(
        () => this.userSidebarTheme,
        userSidebarTheme => {
          const themeName =
            this.resolvedTheme === userSidebarTheme
              ? userSidebarTheme
              : SidebarTheme.MIXED

          this.#analyticsEventService.emit(AnalyticsEventName.THEME_TOGGLE, {
            theme: themeName,
          })
        },
        {
          fireImmediately: false,
        }
      )
    )
  }

  dispose() {
    this.#disposers.forEach(fn => fn())
  }
}
