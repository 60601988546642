import {isPlainObject, keys, merge} from 'lodash-es'
import {amount} from './rules/amount'
import {checked} from './rules/checked'
import {colorCode} from './rules/colorCode'
import {colorContrastRatio} from './rules/colorContrastRatio'
import {email} from './rules/email'
import {iban} from './rules/iban'
import {length} from './rules/length'
import {match} from './rules/match'
import {notUrl} from './rules/notUrl'
import {numeric} from './rules/numeric'
import {password} from './rules/password'
import {phoneNumber} from './rules/phoneNumber'
import {postalCode} from './rules/postalCode'
import {presence} from './rules/presence'
import {regex} from './rules/regex'
import {url} from './rules/url'
import {userEmails} from './rules/userEmails'

///////////////////////////////////////////////////////////////////////////////

export const ALL_RULES = {
  presence,
  checked,
  numeric,
  email,
  userEmails,
  url,
  match,
  regex,
  length,
  amount,
  iban,
  phoneNumber,
  colorContrastRatio,
  notUrl,
  postalCode,
  colorCode,
  password,
}

///////////////////////////////////////////////////////////////////////////////

export type ValidationRules = Record<string, ValidationRule | undefined>

export type ValidationRule<T = any> = (
  args: ValidationRuleArgs<T>
) => ValidationRuleResult

export type ValidationRuleArgs<T> = {
  field: string
  data: any // full form data
  options?: T
}

export type ValidationRuleResult =
  | ValidationError
  | undefined
  | Promise<ValidationError | undefined>
  | Record<string, any>[]

export type ValidationErrors = Record<string, ValidationError[]> | undefined

export type ValidationError = {
  message: string
  data?: any // data for translation interpolation
}

export type ValidationConstraints = Record<string, ValidationConstraint>

export type ValidationConstraint = Record<string, ValidationRuleOptions>

export type ValidationRuleOptions =
  | {
      // This creates a map where the key is the name of the validator function
      // and the value are the options.
      [field in keyof typeof ALL_RULES]?: Parameters<
        (typeof ALL_RULES)[field]
      >[0]['options']
    }
  | boolean

export type ValidationConstraintsBuilder<Values> = (
  values: Values
) => ValidationConstraints | Promise<ValidationConstraints>

///////////////////////////////////////////////////////////////////////////////

export class Validator {
  rules: ValidationRules = ALL_RULES

  async validate(
    data: any,
    constraints: ValidationConstraints
  ): Promise<ValidationErrors> {
    const fields = keys(constraints)
    const allErrors: ValidationErrors = {}

    for (const field of fields) {
      const fieldConstraints = constraints[field]
      const fieldRules = keys(fieldConstraints)
      const fieldErrors: ValidationError[] = []

      for (const rule of fieldRules) {
        const ruleFn = this.rules[rule]
        const options = fieldConstraints[rule]

        let error: ValidationRuleResult = undefined

        if (!ruleFn) {
          throw new Error(`Unknown validation rule "${rule}"`)
        }

        if (options === true) {
          error = await ruleFn({field, data})
        } else if (isPlainObject(options)) {
          error = await ruleFn({field, data, options})
        }

        if (error) {
          fieldErrors.push(error as ValidationError)
        }
      }

      if (fieldErrors.length > 0) {
        allErrors[field] = fieldErrors
      }
    }

    return keys(allErrors).length > 0 ? allErrors : undefined
  }
}

///////////////////////////////////////////////////////////////////////////////

export const addValidationConstraint = (
  constraints: ValidationConstraints,
  field: string,
  rules: ValidationRuleOptions
): ValidationConstraints => {
  constraints[field] = merge<
    object,
    ValidationConstraint,
    ValidationRuleOptions
  >({}, constraints[field], rules)

  return constraints
}

///////////////////////////////////////////////////////////////////////////////

export const isFieldRequired = (
  name: string,
  constraints?: ValidationConstraints
) => {
  return (
    constraints?.[name] &&
    (constraints[name].presence === true || constraints[name].checked === true)
  )
}
