import {type ThemeConfig} from 'antd/lib'
import {getCssProperty} from 'lib/helpers/getCssProperty'

////////////////////////////////////////////////////////////////////////////////

export const getLightThemeConfig = (): ThemeConfig => {
  return {
    components: {
      Table: {
        colorFillSecondary: getCssProperty('--primary-color-light'),
        colorFillAlter: getCssProperty('--primary-color-muted'),
        colorBorderSecondary: getCssProperty('--border-muted'),
        headerSortHoverBg: getCssProperty('--primary-color-light'),
      },
      Menu: {
        itemHoverColor: getCssProperty('--primary-color'),
        itemHoverBg: 'transparent',
        itemSelectedBg: getCssProperty('--primary-color-muted'),
        itemActiveBg: getCssProperty('--primary-color-muted'),
        subMenuItemBg: getCssProperty('--primary-color-muted'),
      },
      Layout: {
        siderBg: getCssProperty('--surface-bg-color'),
        bodyBg: getCssProperty('--bg-color'),
        headerBg: getCssProperty('--surface-bg-color'),
        headerPadding: '0',
        headerHeight: 65,
      },
      Card: {
        colorBorderSecondary: getCssProperty('--border-muted'),
        headerHeight: 0,
      },
    },
    token: {
      fontWeightStrong: 700,
      controlItemBgHover: getCssProperty('--primary-color-muted'),
      colorPrimary: getCssProperty('--primary-color'),
      colorPrimaryHover: getCssProperty('--primary-color__hover'),
      colorLinkHover: getCssProperty('--primary-color__hover'),
      colorPrimaryActive: getCssProperty('--primary-color-active'),
      colorText: getCssProperty('--text-color'),
      colorInfo: getCssProperty('--primary-color'),
      colorBgMask: 'rgba(49,68,90,.45)',
      fontFamily:
        "'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    },
  }
}
