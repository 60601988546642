import {get} from 'lodash-es'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type EmailsOptions = {
  responseValidationArr: {email: string; error: EmailErrors}[]
  values: string[]
}

export enum EmailErrors {
  INVALID_EMAIL = 'invalid_email',
  ALREADY_INVITED = 'already_invited',
  ANOTHER_ORGANISATION = 'another_organisation',
}

///////////////////////////////////////////////////////////////////////////////

export const userEmails: ValidationRule<EmailsOptions> = ({
  field,
  data,
  options,
}) => {
  const value = get<string | undefined>(data, field, '') ?? ''
  const {responseValidationArr, values} = options ?? {}
  const messages: {message: string; data: Record<string, any>}[] = []

  if (!value) {
    return
  }

  if (responseValidationArr?.length === 0) {
    return
  }

  const filterEmails = (error: EmailErrors) => {
    const emails = values?.filter(
      email =>
        responseValidationArr?.find(e => e.email === email)?.error === error
    )

    return emails?.length === 1 ? emails[0] : emails?.join(', ')
  }

  const invalidEmails = filterEmails(EmailErrors.INVALID_EMAIL)
  const alreadyInvitedEmails = filterEmails(EmailErrors.ALREADY_INVITED)
  const anotherOrgEmails = filterEmails(EmailErrors.ANOTHER_ORGANISATION)

  if (invalidEmails) {
    messages.push({
      message: 'user_emails.invalid',
      data: {invalid: invalidEmails},
    })
  }

  if (alreadyInvitedEmails) {
    messages.push({
      message: 'user_emails.invited',
      data: {invited: alreadyInvitedEmails},
    })
  }

  if (anotherOrgEmails) {
    messages.push({
      message: 'user_emails.another_organisation',
      data: {another_organisation: anotherOrgEmails},
    })
  }

  return messages
}
