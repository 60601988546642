import {get} from 'lodash-es'
import {
  entropy,
  getPasswordStrength,
  PasswordStrength,
} from 'lib/helpers/entropy'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export const password: ValidationRule = ({field, data}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  const bits = entropy(value)
  const strength = getPasswordStrength(bits)

  if (
    strength === PasswordStrength.VERY_WEAK ||
    strength === PasswordStrength.WEAK
  ) {
    return {message: `password.${strength}`}
  }
}
