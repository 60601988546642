import {type TFunction} from 'i18next'
import {type OptionItem} from 'types/OptionItem'

export const getEnumTranslations = (
  t: TFunction,
  obj: Record<string, string>,
  prefix: string
): OptionItem[] => {
  const optionItems: OptionItem[] = []

  for (const key in obj) {
    const translation = t(`${prefix}.${obj[key]}`)

    optionItems.push({
      value: key,
      label: translation,
    })
  }

  return optionItems
}
