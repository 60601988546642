import {type TFunction} from 'i18next'
import {map} from 'lodash-es'
import {type ValidationError} from 'lib/validator'

///////////////////////////////////////////////////////////////////////////////

export type GetFieldValidationTranslationOptions = {
  name: string
  error: ValidationError
  isHTML?: boolean
}

///////////////////////////////////////////////////////////////////////////////

export const getFieldValidationTranslation = (
  t: TFunction,
  options: GetFieldValidationTranslationOptions
): string | null => {
  const {name, error, isHTML} = options

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!error) {
    return null
  }

  if (Array.isArray(error) && error.length > 0 && isHTML) {
    return error
      .map(error => getFieldValidationTranslation(t, {name, error}))
      .join('<br/>')
  }

  const {message: code, data} = error
  // const names = getFieldNameFallbacks(name)
  const names = [name]
  const keys = map(names, v => `validation.${v}.${code}`)
  keys.push(`validation.${code}`)
  return (t(keys, {...data, defaultValue: ''}) as string) || null
}
