import {get} from 'lodash-es'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type MatchOptions = {
  field: string
}

///////////////////////////////////////////////////////////////////////////////

export const match: ValidationRule<MatchOptions> = ({field, data, options}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  const {field: matchField} = options ?? {}
  const matchValue = get<string | undefined>(data, matchField ?? '', '') ?? ''

  if (matchValue !== value) {
    return {message: 'match'}
  }
}
