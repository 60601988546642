import {get} from 'lodash-es'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export const presence: ValidationRule = ({field, data, options}) => {
  const value = get<string | undefined>(data, field, '') ?? ''
  const {text} = options ?? {}

  if ([null, undefined, ''].includes(value)) {
    return {message: text ? `custom.${text}` : 'presence'}
  }
}
