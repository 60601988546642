import {parse, stringify} from 'qs'

///////////////////////////////////////////////////////////////////////////////

export const getSearchFromParams = (params: Record<string, any>) => {
  return stringify(params, {addQueryPrefix: true})
}

export const getParamsFromSearch = (search: string) => {
  return parse(search, {ignoreQueryPrefix: true}) as Record<string, any>
}
