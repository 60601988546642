import {type FC} from 'react'
import {Select as AntSelect} from 'antd'
import {type SelectProps as AntSelectProps} from 'antd/lib/select'
import {get} from 'lodash-es'
import {observer} from 'mobx-react-lite'
import {useFormHandler} from 'new/hooks/useFormHandler'
import {type OptionItem} from 'types/OptionItem'
import {
  FORM_ITEM_DEFAULT_PROPS,
  FormItem,
  type FormItemBaseProps,
} from './FormItem'

///////////////////////////////////////////////////////////////////////////////

export type SelectProps = FormItemBaseProps &
  Omit<AntSelectProps, 'options'> & {
    options: OptionItem[]
    prepareValue?: (
      value: OptionItem['value'],
      values: any
    ) => OptionItem['value'] | undefined | null
  }

///////////////////////////////////////////////////////////////////////////////

export const Select: FC<SelectProps> = observer(props => {
  const form = useFormHandler()
  const {initialValues, values} = form

  const {
    name,
    label,
    skipLabel,
    labelIcon,
    registerOnMount,
    unregisterOnUnmount,
    untouchOnUnmount,
    resetOnUnmount,
    disabled,
    errorMessageType,
    showErrorOnUntouched,
    showWarningOnUntouched,
    htmlErrorMessage,
    options,
    prepareValue,
    ...rest
  } = {...SELECT_DEFAULT_PROPS, ...props}

  const initialValue: string = get(initialValues, name, '')
  const value: string = get(values, name, '')

  const onSearch = () => null

  const filterOption: AntSelectProps['filterOption'] = (input, option) => {
    try {
      const label = (option?.children ?? '') as string
      return label.toLowerCase().includes(input.toLowerCase())
    } catch (error) {
      return false
    }
  }

  const onChange: AntSelectProps['onChange'] = value => {
    const preparedValue = prepareValue ? prepareValue(value, values) : value

    form.setFieldValue(name, preparedValue)
  }

  const onBlur: AntSelectProps['onBlur'] = () => {
    if (value !== initialValue) {
      form.setFieldTouched(name, true)
    }
  }

  return (
    <FormItem
      name={name}
      label={label}
      skipLabel={skipLabel}
      labelIcon={labelIcon}
      registerOnMount={registerOnMount}
      unregisterOnUnmount={unregisterOnUnmount}
      untouchOnUnmount={untouchOnUnmount}
      resetOnUnmount={resetOnUnmount}
      disabled={disabled}
      errorMessageType={errorMessageType}
      showErrorOnUntouched={showErrorOnUntouched}
      showWarningOnUntouched={showWarningOnUntouched}
      htmlErrorMessage={htmlErrorMessage}
    >
      <AntSelect
        {...rest}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onSearch={onSearch}
        filterOption={filterOption}
        data-cy={`select.${name}`}
        data-testid={`select.${name}`}
      >
        {options.map((option, i) => (
          <AntSelect.Option
            key={i}
            value={option.value}
            data-cy={`select.option.${option.value}`}
            data-testid={`select.option.${option.value}`}
          >
            {option.label}
          </AntSelect.Option>
        ))}
      </AntSelect>
    </FormItem>
  )
})

const SELECT_DEFAULT_PROPS: Partial<SelectProps> = {
  ...FORM_ITEM_DEFAULT_PROPS,
  showSearch: true,
  status: undefined,
  size: 'large',
}
