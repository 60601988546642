import {observable} from 'mobx'
import {Language} from 'types/Language'

////////////////////////////////////////////////////////////////////////////////

/**
 * This gloabal variable is needed to be able to provide the language on the
 * very top level of the app.
 *
 * Handling the language is actually done in `I18nService`, which has
 * `RouterStore` as a dependency, but `RouterStore` can be initialized only
 * in the context of the `RouterProvider`.
 * At the same time, we need language to be available before the `RouterProvider`
 * is initialized to pass locale to the `AntConfigProvider`, which is higher
 * in the components tree.
 * This value is updated by `I18nService` when the language is changed/auto-detected.
 */
export const LANGUAGE = observable.box<Language>(Language.EN)
