import {type FC} from 'react'
import {useTranslation} from 'react-i18next'
import {provider, useInstance} from 'react-ioc'
import {InfoCircleOutlined} from '@ant-design/icons'
import {Button, Flex, Popover, Typography} from 'antd'
import {observer} from 'mobx-react-lite'
import {Modal} from 'components/Modal'
import {Checkbox} from 'new/form/Checkbox'
import {Select} from 'new/form/Select'
import {FormHandlerProvider} from 'new/hooks/useFormHandler'
import {FormExportStore} from 'new/stores/FormExport'
import {ExportFormat, type ExportOptions} from '..'
import styles from './index.module.scss'

///////////////////////////////////////////////////////////////////////////////

type Props = {
  isOpened: boolean
  disabled: boolean
  onExport: (values: ExportOptions) => void
  close: () => void
}

///////////////////////////////////////////////////////////////////////////////

const {Title, Text} = Typography

///////////////////////////////////////////////////////////////////////////////

const NAMESPACE = 'export_modal'

///////////////////////////////////////////////////////////////////////////////

export const ExportModal: FC<Props> = provider(FormExportStore)(
  observer(props => {
    const {isOpened, disabled, onExport, close} = props
    const {t} = useTranslation(NAMESPACE)

    const formExportStore = useInstance(FormExportStore)
    const {form, values, initialValues} = formExportStore

    const formatOptions = Object.values(ExportFormat)
      .map(option => ({
        value: option,
        label: t(`common:controls.export.types.${option as string}`),
      }))
      // Default selected one should be first in the list
      .sort(a => (a.label === initialValues.export_format ? 1 : -1))

    const onClose = () => {
      formExportStore.reset()
      close()
    }

    return (
      <Modal open={isOpened} onCancel={close}>
        <FormHandlerProvider form={form}>
          <Flex gap="small" vertical>
            <Title level={3}>{t(`title`)}</Title>
            <Select
              disabled={disabled}
              name="export_format"
              options={formatOptions}
            />

            <div>
              <Text>{t(`advanced_options.title`)}</Text>
              <Checkbox
                name="include_custom_fields"
                disabled={disabled}
                labelIcon={
                  <Popover
                    placement="right"
                    content={
                      <div
                        className={styles.popOver}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            `advanced_options.include_custom_fields_info_html`
                          ),
                        }}
                      />
                    }
                  >
                    <InfoCircleOutlined />
                  </Popover>
                }
              />
            </div>

            <div>
              <Flex gap="small" justify="end">
                <Button
                  size="large"
                  onClick={() => onClose()}
                  disabled={disabled}
                  data-cy="export.modal.cancel"
                >
                  {t('common:controls.cancel')}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  onClick={() =>
                    onExport({
                      exportFormat: values.export_format,
                      includeCustomFields: values.include_custom_fields,
                    })
                  }
                  loading={disabled}
                  disabled={disabled}
                  data-cy="export.modal.export"
                  data-testid="export.modal.export"
                >
                  {t('common:controls.export.title')}
                </Button>
              </Flex>
            </div>
          </Flex>
        </FormHandlerProvider>
      </Modal>
    )
  })
)
