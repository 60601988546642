import {get} from 'lodash-es'
import isURL from 'validator/lib/isURL'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type UrlOptions = {
  requireProtocol?: boolean
  urlExample?: string
}
///////////////////////////////////////////////////////////////////////////////
export const url: ValidationRule<UrlOptions> = ({field, data, options}) => {
  const value = get<string | undefined>(data, field, '') ?? ''
  const {urlExample} = options ?? {}

  if (!value) {
    return
  }

  if (!isURL(value)) {
    return {message: 'url'}
  }

  if (
    !isURL(value, {
      protocols: ['http', 'https'],
      require_protocol: options?.requireProtocol ?? false,
      require_valid_protocol: true,
    })
  ) {
    return {message: 'url_protocol.message', data: {urlExample}}
  }
}
