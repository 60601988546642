import {get} from 'lodash-es'
import {formatAmount} from 'lib/helpers/formatters'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type AmountOptions = {
  minimum?: number
  maximum?: number
  currency?: string
}

///////////////////////////////////////////////////////////////////////////////

export const amount: ValidationRule<AmountOptions> = ({
  field,
  data,
  options,
}) => {
  const amount = get<number | undefined>(data, field, 0) ?? 0

  if (!amount && amount !== 0) {
    return
  }

  const {minimum, maximum, currency} = options ?? {}
  const formattedMaximum = formatAmount(maximum ?? 0)

  if (minimum && amount < minimum) {
    return {
      message: 'amount.too_low',
      data: {
        minimum: `${currency} ${minimum}`,
        maximum: `${currency} ${formattedMaximum}`,
      },
    }
  }

  if (maximum && amount > maximum) {
    return {
      message: 'amount.too_high',
      data: {
        minimum: `${currency} ${minimum}`,
        maximum: `${currency} ${formattedMaximum}`,
      },
    }
  }
}
