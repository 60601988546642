export const getCountryCodeByTimezone = async (tz = '') => {
  const {timezones} = await import('./timezones')
  const timezone = tz || Intl.DateTimeFormat().resolvedOptions().timeZone

  if (timezone === '' || !timezone) {
    return undefined
  }

  const countryCode: string = timezones[timezone].c[0]

  return countryCode
}
