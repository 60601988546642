import {inject} from 'react-ioc'
import type {
  NavigateOptions,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import {makeAutoObservable, reaction} from 'mobx'
import {
  getParamsFromSearch,
  getSearchFromParams,
} from 'lib/helpers/searchParams'
import {ctx} from 'new/ctx'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {AnalyticsEventName} from 'types/AnalyticsEventName'

////////////////////////////////////////////////////////////////////////////////

type State = {
  navigate: ReturnType<typeof useNavigate>
  location: ReturnType<typeof useLocation>
  params: ReturnType<typeof useParams<string>>
}

////////////////////////////////////////////////////////////////////////////////

export class RouterStore {
  #analyticsEventService = inject(this, AnalyticsEventService)
  #disposers: (() => void)[] = []

  navigate!: State['navigate']
  location!: State['location']
  params!: State['params']
  isReady = false

  constructor() {
    ctx.routerStore = this
    makeAutoObservable(this)
    this.#initEffects()
  }

  setState = (state: State) => {
    const {navigate, location, params} = state
    this.navigate = navigate
    this.location = location
    this.params = params
    this.isReady = true
  }

  get searchParams() {
    return getParamsFromSearch(this.location.search)
  }

  setSearchParams = (
    searchParams: Record<string, any>,
    options: NavigateOptions = {}
  ) => {
    const {pathname, hash} = this.location
    const search = getSearchFromParams(searchParams)
    this.navigate({pathname, search, hash}, options)
  }

  #initEffects() {
    this.#disposers.push(
      reaction(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        () => this.location?.pathname,
        pathname => {
          if (pathname) {
            // prettier-ignore
            this.#analyticsEventService.emit(AnalyticsEventName.PAGEVIEW, {$pathname: pathname})
          }
        },
        {
          fireImmediately: true,
        }
      )
    )
  }

  dispose() {
    this.#disposers.forEach(fn => fn())
  }
}
