import {type FormEvent, type MouseEvent} from 'react'
import {inject} from 'react-ioc'
import {debounce, get, keys, pick, set} from 'lodash-es'
import {comparer, makeAutoObservable, reaction, toJS} from 'mobx'
import {toGenerator} from 'lib/helpers/generators'
import {focusTopInvalidField, scrollToTopInvalidField} from 'lib/helpers/scroll'
import {
  type ValidationConstraints,
  type ValidationErrors,
  Validator,
} from 'lib/validator'

///////////////////////////////////////////////////////////////////////////////

type FormHandlerOptions<Values> = {
  getInitialValues: () => Values
  getValues: () => Values
  getValidationConstraints?: () => ValidationConstraints
  getWarnConstraints?: () => ValidationConstraints
}

type SubmitHandler<Values> = (values: Values) => void | Promise<void>

///////////////////////////////////////////////////////////////////////////////

export class FormHandler<Values extends Record<string, any> | undefined> {
  #getInitialValues: () => Values
  #getValues: () => Values
  #getValidationConstraints: () => ValidationConstraints = () => ({})
  #getWarnConstraints: () => ValidationConstraints = () => ({})

  registeredFields: string[] = []
  touchedFields: string[] = []

  validationErrors: ValidationErrors = {}
  warnErrors: ValidationErrors = {}

  isValidating = false
  isSubmitting = false
  submitCount = 0

  #validator = inject<Validator>(this, Validator)
  #disposers: (() => void)[] = []

  constructor(options: FormHandlerOptions<Values>) {
    const {
      getInitialValues,
      getValues,
      getValidationConstraints,
      getWarnConstraints,
    } = options

    this.#getInitialValues = getInitialValues
    this.#getValues = getValues

    if (getValidationConstraints) {
      this.#getValidationConstraints = getValidationConstraints
    }

    if (getWarnConstraints) {
      this.#getWarnConstraints = getWarnConstraints
    }

    makeAutoObservable(this)
    this.#initEffects()
  }

  setFieldRegistered(name: string, isRegistered: boolean) {
    if (isRegistered) {
      if (!this.registeredFields.includes(name)) {
        this.registeredFields.push(name)
      }
    } else {
      const idx = this.registeredFields.indexOf(name)

      if (idx !== -1) {
        this.registeredFields.splice(idx, 1)
      }
    }
  }

  setFieldTouched(name: string, isTouched: boolean) {
    if (isTouched) {
      if (!this.touchedFields.includes(name)) {
        this.touchedFields.push(name)
      }
    } else {
      const idx = this.touchedFields.indexOf(name)

      if (idx !== -1) {
        this.touchedFields.splice(idx, 1)
      }
    }
  }

  getFieldValue(name: string, defaultValue?: any) {
    return get(this.values, name, defaultValue)
  }

  setFieldValue(name: string, value: any) {
    if (this.values) {
      set(this.values, name, value)
    }
  }

  updateValues(updates: Partial<Values> | Record<string, any>) {
    Object.entries(updates).forEach(([fieldName, value]) => {
      this.setFieldValue(fieldName, value)
    })
  }

  async #validate() {
    const validationErrors: ValidationErrors = await this.#validator.validate(
      this.values,
      this.validationConstraints
    )
    return validationErrors
  }

  async #warn() {
    const warnErrors: ValidationErrors = await this.#validator.validate(
      this.values,
      this.warnConstraints
    )
    return warnErrors
  }

  *runAllValidations() {
    this.isValidating = true

    const validationErrors = yield* toGenerator(this.#validate())
    const warnErrors = yield* toGenerator(this.#warn())

    this.validationErrors = pick(
      validationErrors,
      this.registeredFields
    ) as ValidationErrors

    this.warnErrors = pick(
      warnErrors,
      this.registeredFields
    ) as ValidationErrors

    this.isValidating = false
  }

  *submit(onSubmit: SubmitHandler<Values>) {
    this.submitCount++
    this.touchedFields = [...toJS(this.registeredFields)]
    yield this.runAllValidations()

    if (this.isValid) {
      try {
        this.isSubmitting = true
        yield onSubmit(this.values)
      } finally {
        this.isSubmitting = false
      }
    }
  }

  handleSubmit(onSubmit: SubmitHandler<Values>) {
    return (event?: FormEvent<HTMLFormElement> | MouseEvent<HTMLElement>) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      this.submit(onSubmit)
    }
  }

  get initialValues() {
    return this.#getInitialValues()
  }

  get values() {
    return this.#getValues()
  }

  get validationConstraints() {
    return this.#getValidationConstraints()
  }

  get warnConstraints() {
    return this.#getWarnConstraints()
  }

  get isValid() {
    return keys(this.validationErrors).length === 0
  }

  get isInvalid() {
    return !this.isValid
  }

  #initEffects() {
    // Trigger "runAllValidations" when "values" are changed
    this.#disposers.push(
      reaction(
        () => toJS(this.values),
        () => this.runAllValidationsDebounced(),
        {
          fireImmediately: false,
          equals: comparer.structural,
        }
      )
    )

    // On submit in case of validation errors:
    // - scroll to top invalid field
    // - focus top invalid field
    this.#disposers.push(
      reaction(
        () => this.submitCount,
        () => {
          const run = async () => {
            await scrollToTopInvalidField()
            await focusTopInvalidField()
          }

          void run()
        },
        {
          fireImmediately: false,
        }
      )
    )
  }

  dispose() {
    this.#disposers.forEach(fn => fn())
  }

  runAllValidationsDebounced = debounce(
    () => {
      this.runAllValidations()
    },
    200,
    {
      leading: true,
      trailing: true,
    }
  )
}
