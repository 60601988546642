import type {PropsWithChildren} from 'react'
import {createContextProvider} from 'lib/helpers/createContextProvider'

///////////////////////////////////////////////////////////////////////////////

export type NamespaceProviderProps = PropsWithChildren<{
  ns: string
  prefix?: string
}>

///////////////////////////////////////////////////////////////////////////////

export const [NamespaceProvider, useNamespace] = createContextProvider(
  (props: NamespaceProviderProps) => {
    const {ns, prefix} = props

    return {ns, prefix}
  }
)
