import {inject} from 'react-ioc'
import {type AxiosError} from 'axios'
import {makeAutoObservable, reaction, toJS} from 'mobx'
import {addUrlProtocol} from 'lib/helpers/addUrlProtocol'
import {toGenerator} from 'lib/helpers/generators'
import {getCountryCodeByTimezone} from 'lib/helpers/getCountryCodeByTimezone'
import {getLocale} from 'lib/helpers/locale'
import {
  addValidationConstraint,
  type ValidationConstraints,
} from 'lib/validator'
import {ctx} from 'new/ctx'
import {AnalyticsEventService} from 'new/services/analytics/AnalyticsEventService'
import {BugsnagService} from 'new/services/analytics/BugsnagService'
import {FormHandler} from 'new/services/FormHandler'
import {I18nService} from 'new/services/I18nService'
import {OnboardingApiService} from 'new/services/OnboardingApiService'
import {AnalyticsEventName} from 'types/AnalyticsEventName'
import {type OnboardingData} from 'types/OnboardingData'
import {AppConfigStore} from './AppConfigStore'
import {RouterStore} from './RouterStore'
import {SessionStore} from './SessionStore'

///////////////////////////////////////////////////////////////////////////////

type FnExecuteRecaptcha = (action?: string) => Promise<string>

///////////////////////////////////////////////////////////////////////////////

export class FormOnboardingStore {
  #i18nService = inject<I18nService>(this, I18nService)
  #appConfigStore = inject<AppConfigStore>(this, AppConfigStore)
  #onboardingApiService = inject<OnboardingApiService>(
    this,
    OnboardingApiService
  )
  #analyticsEventService = inject<AnalyticsEventService>(
    this,
    AnalyticsEventService
  )
  #bugsnagService = inject<BugsnagService>(this, BugsnagService)
  #routerStore = inject(this, RouterStore)
  #sessionStore = inject(this, SessionStore)
  #disposers: (() => void)[] = []
  #t = this.#i18nService.i18n.t
  #ns = 'validation'

  // state
  values: OnboardingData = toJS(this.initialValues)
  form: FormHandler<OnboardingData>
  isReady = false
  executeRecaptcha?: FnExecuteRecaptcha

  // sending falgs
  isSending = false
  sendingError?: AxiosError<any>

  constructor() {
    ctx.formOnboardingStore = this
    makeAutoObservable(this)

    this.form = new FormHandler({
      getInitialValues: () => toJS(this.initialValues),
      getValues: () => this.values,
      getValidationConstraints: () => this.validationConstraints,
    })
    this.#initEffects()
    this.detectCountryCode()
  }

  get initialValues(): OnboardingData {
    return {
      organisation: {
        name: '',
        website_url: '',
        merchant_category_code: '',
        locale: '',
        // country_code: '',
        hubspot_id: '',
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        locale: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      recaptcha_token: '',
      terms_accepted: false,
    }
  }

  get validationConstraints(): ValidationConstraints {
    const constraints: ValidationConstraints = {}

    addValidationConstraint(constraints, 'organisation.name', {
      presence: true,
      length: {minimum: 3, maximum: 48},
      regex: {test: /^[\p{L}\p{M}\p{N}\p{P}\p{Zs}]+$/u},
    })
    addValidationConstraint(
      constraints,
      'organisation.merchant_category_code',
      {
        presence: true,
      }
    )
    addValidationConstraint(constraints, 'organisation.country_code', {
      presence: true,
    })
    addValidationConstraint(constraints, 'organisation.website_url', {
      url: {
        requireProtocol: true,
        urlExample: this.#t(
          `${this.#ns}.url_protocol.url_example_organisation`
        ),
      },
      length: {maximum: 200},
    })
    addValidationConstraint(constraints, 'organisation.locale', {
      presence: true,
    })

    addValidationConstraint(constraints, 'user.email', {
      presence: true,
      email: true,
      length: {maximum: 180},
    })
    addValidationConstraint(constraints, 'user.password', {
      presence: true,
      password: true,
      length: {minimum: 12, maximum: 64},
    })
    addValidationConstraint(constraints, 'user.first_name', {
      presence: true,
      notUrl: true,
      length: {maximum: 180},
    })
    addValidationConstraint(constraints, 'user.last_name', {
      presence: true,
      length: {maximum: 180},
    })
    addValidationConstraint(constraints, 'user.locale', {presence: true})
    addValidationConstraint(constraints, 'user.timezone', {presence: true})

    addValidationConstraint(constraints, 'terms_accepted', {checked: true})

    return constraints
  }

  *detectCountryCode() {
    let countryCode: string | undefined = undefined
    try {
      countryCode = yield* toGenerator(getCountryCodeByTimezone())
    } catch (error) {
      // do nothing
    }

    this.form.updateValues({
      'organisation.country_code': countryCode,
    })
    this.isReady = true
  }

  *send() {
    try {
      this.isSending = true
      this.sendingError = undefined

      this.#analyticsEventService.emit(AnalyticsEventName.SIGN_UP_FORM_SUBMIT)
      const payload = yield* toGenerator(this.#prepareData())

      yield this.#onboardingApiService.send(payload)
    } catch (error: any) {
      // skip 409 errors (Hubspot ID conflict, User already exists)
      if (error?.response?.status !== 409) {
        this.#bugsnagService.notifyError(error, {
          formValues: this.#prepareData(),
        })
      }
      this.sendingError = error
    } finally {
      this.isSending = false
    }
  }

  async #prepareData(): Promise<OnboardingData> {
    const data = toJS(this.values)

    delete data.terms_accepted

    data.organisation.website_url = addUrlProtocol(
      data.organisation.website_url
    )
    data.recaptcha_token = (await this.executeRecaptcha?.('onboarding')) ?? ''

    const searchParams = new URLSearchParams(window.location.search)
    data.organisation.utm_source = searchParams.get('utm_source') ?? undefined
    data.organisation.utm_medium = searchParams.get('utm_medium') ?? undefined
    data.organisation.utm_campaign =
      searchParams.get('utm_campaign') ?? undefined

    return data
  }

  #initEffects() {
    this.#disposers.push(
      reaction(
        () => this.#appConfigStore.data.language,
        language => {
          this.form.updateValues({
            'user.locale': getLocale(language),
            'organisation.locale': getLocale(language),
          })
        },
        {
          fireImmediately: true,
        }
      )
    ),
      this.#disposers.push(
        reaction(
          () => this.#routerStore.location,
          () => {
            const orgData = this.#sessionStore.getDecryptedOrganisationData()
            if (orgData?.hubspotId) {
              this.form.updateValues({
                organisation: {
                  name: orgData.name,
                  hubspot_id: orgData.hubspotId,
                },
              })
            }
          },
          {
            fireImmediately: true,
          }
        )
      )
  }

  dispose() {
    this.#disposers.forEach(fn => fn())
  }
}
