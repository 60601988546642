import 'dayjs/locale/de'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import {initReactI18next} from 'react-i18next'
import {inject} from 'react-ioc'
import dayjs from 'dayjs'
import type i18next from 'i18next'
import {createInstance} from 'i18next'
import {comparer, reaction} from 'mobx'
import {LANGUAGE} from 'config/language'
import {UI_LANGUAGE} from 'constants/cookie_entry_names'
import {getKeysWithDifferentValues} from 'lib/helpers/getKeysWithDifferentValues'
import {ctx} from 'new/ctx'
import {CookieStorageService} from 'new/services/CookieStorageService'
import {I18nBackend, type TranslationModule} from 'new/services/I18nBackend'
import {AppConfigStore} from 'new/stores/AppConfigStore'
import {RouterStore} from 'new/stores/RouterStore'
import type {Language} from 'types/Language'

///////////////////////////////////////////////////////////////////////////////

export class I18nService {
  #routerStore = inject<RouterStore>(this, RouterStore)
  #appConfigStore = inject<AppConfigStore>(this, AppConfigStore)
  #cookieStorage = inject<CookieStorageService>(this, CookieStorageService)
  i18n: typeof i18next
  #disposers: (() => void)[] = []

  constructor() {
    this.#autodetectAndSetLanguage()

    /**
     * Creates a map of language resolvers:
     *
     * ```
     * {
     *  '../../translations/en.yml': () => import('../../translations/en.yml').then(m => m.default),
     *  '../../translations/de.yml': () => import('../../translations/de.yml').then(m => m.default),
     *  '../../translations/fr.yml': () => import('../../translations/fr.yml').then(m => m.default),
     *  '../../translations/it.yml': () => import('../../translations/it.yml').then(m => m.default),
     * }
     * ```
     */
    const translationModuleResolvers = import.meta.glob<TranslationModule>(
      '../../translations/*.yml',
      {import: 'default'}
    )

    const backend = new I18nBackend({
      translationModuleResolvers,
    })

    this.i18n = createInstance()
    this.i18n.use(backend)
    this.i18n.use(initReactI18next)

    this.i18n.on('languageChanged', lang => {
      // eslint-disable-next-line import/no-named-as-default-member
      dayjs.locale(lang)
      document.documentElement.lang = lang
    })

    this.#initI18n()

    // // todo: figure out proper HMR for translations
    // if (import.meta.hot) {
    //   const translationModuleNames = Object.keys(translationModuleResolvers)

    //   import.meta.hot.accept(translationModuleNames, () => {
    //     // Reload translations only for languages, which are already loaded.
    //     void this.i18n.reloadResources(Object.keys(this.i18n.store.data))
    //   })
    // }

    this.#initEffects()

    ctx.i18nService = this
  }

  #autodetectAndSetLanguage() {
    const supportedLangs = ['en', 'de', 'fr', 'it']

    const detectLanguage = (): string => {
      // 1. URL parameter "lang"
      const langParam = this.#routerStore.searchParams.lang ?? ''

      if (supportedLangs.includes(langParam)) {
        return langParam
      }

      // 2. Cookie
      const langCs = this.#cookieStorage.get(UI_LANGUAGE)

      if (supportedLangs.includes(langCs)) {
        return langCs
      }

      // 3. Browser settings
      for (const language of navigator.languages) {
        const lang = language.slice(0, 2).toLocaleLowerCase()

        if (supportedLangs.includes(lang)) {
          return lang
        }
      }

      // 4. Fallback
      return this.#appConfigStore.data.language
    }

    this.#appConfigStore.update({language: detectLanguage()})
  }

  #initI18n() {
    const {language, fallback_language} = this.#appConfigStore.data

    void this.i18n.init({
      debug: false,
      lng: language,
      fallbackLng: fallback_language,
      ns: 'common',
      fallbackNS: 'common',
      defaultNS: 'common',
      returnEmptyString: true,
      interpolation: {
        escapeValue: false,
      },
      react: {
        bindI18n: 'loaded languageChanged',
        bindI18nStore: 'added removed',
      },
    })
  }

  #initEffects() {
    /**
     * When "config.language" is changed, trigger "i18n.changeLanguage".
     * When "config.fallback_language" is changed, re-init "i18n".
     */
    this.#disposers.push(
      reaction(
        () => {
          const {language, fallback_language} = this.#appConfigStore.data
          return {language, fallback_language}
        },
        (nv, ov) => {
          const changedKeys = getKeysWithDifferentValues(nv, ov)

          if (changedKeys.length === 1 && changedKeys[0] === 'language') {
            void this.i18n.changeLanguage(nv.language)
          } else {
            this.#initI18n()
          }

          this.#cookieStorage.set(UI_LANGUAGE, nv.language)
        },
        {
          fireImmediately: false,
          equals: comparer.structural,
        }
      )
    )

    /**
     * When "config.language" is changed, set gloabal "language" observable.
     */
    this.#disposers.push(
      reaction(
        () => this.#appConfigStore.data.language,
        lang => LANGUAGE.set(lang as Language),
        {
          fireImmediately: true,
        }
      )
    )
  }

  dispose() {
    this.#disposers.forEach(fn => fn())
  }
}
