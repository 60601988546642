import {type Locale as AntLocale} from 'antd/lib/locale'
import deDE from 'antd/lib/locale/de_DE'
import enGB from 'antd/lib/locale/en_GB'
import frFR from 'antd/lib/locale/fr_FR'
import itIT from 'antd/lib/locale/it_IT'

///////////////////////////////////////////////////////////////////////////////

export const getLocale = (localeOrLanguage: string): string => {
  const mapping: Record<string, string> = {
    en: 'en_GB',
    de: 'de_CH',
    fr: 'fr_CH',
    it: 'it_CH',
    en_GB: 'en_GB',
    de_CH: 'de_CH',
    fr_CH: 'fr_CH',
    it_CH: 'it_CH',
  }

  return mapping[localeOrLanguage] || 'en_GB'
}

///////////////////////////////////////////////////////////////////////////////

export const getLang = (locale: string): string => {
  const mapping: Record<string, string> = {
    en_GB: 'en',
    de_CH: 'de',
    fr_CH: 'fr',
  }

  return mapping[locale] || 'en'
}

///////////////////////////////////////////////////////////////////////////////

export const getAntLocale = (language: string): AntLocale => {
  const mapping: Record<string, AntLocale> = {
    en: enGB,
    de: deDE,
    fr: frFR,
    it: itIT,
  }

  return mapping[language] ?? enGB
}
