import {get} from 'lodash-es'
import isEmail from 'validator/lib/isEmail'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export const email: ValidationRule = ({field, data}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  if (!isEmail(value)) {
    return {message: 'email'}
  }
}
