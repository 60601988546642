import {get} from 'lodash-es'
import isURL from 'validator/lib/isURL'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export const notUrl: ValidationRule = ({field, data}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  if (isURL(value)) {
    return {message: 'not_url'}
  }
}
