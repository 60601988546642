import {useMemo} from 'react'
import {WebsiteLanguage} from 'types/WebsiteLanguage'
import {useAppConfig} from './useAppConfig'

////////////////////////////////////////////////////////////////////////////////

const italianTermsLink =
  'https://www.raisenow.com/hubfs/pdf/AGB%20IT_%20Termini%20e%20condizioni%20generali%20per%20il%20software%20as%20a%20service.pdf'
const italianPrivacyLink =
  'https://www.raisenow.com/hubfs/pdf/PricacyPolicy%20IT_%20La%20nostra%20informativa%20sulla%20privacy.pdf'

////////////////////////////////////////////////////////////////////////////////

const useWebsiteLocale = () => {
  const {language} = useAppConfig()

  const locale = useMemo(() => {
    if (language === 'fr') {
      return 'fr-ch'
    }

    const regions = navigator.languages.map(locale =>
      new Intl.Locale(locale).region?.toLowerCase()
    )

    if (language === 'en') {
      if (regions.includes('ch')) {
        return 'en-ch'
      } else {
        return 'en'
      }
    }

    if (language === 'de') {
      for (const region of regions) {
        if (region && ['at', 'ch', 'de'].includes(region)) {
          return 'de-' + region
        }
      }
    }

    return 'de-ch'
  }, [language])

  return {language: language as WebsiteLanguage, locale}
}

////////////////////////////////////////////////////////////////////////////////

export enum Webpage {
  TERMS,
  PRIVACY,
  COOPERATION_GUIDELINES,
}

const path: {[key in Webpage]: {[key in WebsiteLanguage]: string}} = {
  [Webpage.TERMS]: {
    de: 'saas-agb',
    en: 'saas-policy',
    fr: 'saas-conditions',
    it: 'saas-condizioni',
  },
  [Webpage.PRIVACY]: {
    de: 'datenschutz',
    en: 'privacy-policy',
    fr: 'politique-de-confidentialite',
    it: 'intimita',
  },
  [Webpage.COOPERATION_GUIDELINES]: {
    de: 'richtlinien-der-zusammenarbeit',
    en: 'cooperation-guidelines',
    fr: 'directives-cooperation',
    it: 'linee-di-collaborazione',
  },
}

export const useWebsiteLink = (webpage: Webpage) => {
  const {language, locale} = useWebsiteLocale()

  if (language === WebsiteLanguage.IT) {
    if (webpage === Webpage.TERMS) {
      return italianTermsLink
    } else if (webpage === Webpage.PRIVACY) {
      return italianPrivacyLink
    }
  }

  return `https://www.raisenow.com/${locale}/${path[webpage][language]}`
}
