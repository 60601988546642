import {get} from 'lodash-es'
import {postalCodes} from 'lib/helpers/postalCodes'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type PostalCode = {
  countryCode?: string
}

///////////////////////////////////////////////////////////////////////////////

export const postalCode: ValidationRule<PostalCode> = ({
  field,
  data,
  options,
}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  const {countryCode} = options ?? {}
  const regex = /^[\w\d\s-]*[\w\d]$/ // Allow characters, numbers, spaces, hyphen, at the end allow only numbers and characters
  const invalidPostalCode = !regex.test(value)

  if (invalidPostalCode) {
    return {message: 'regex'}
  }

  const countryPostalCodes = postalCodes.filter(
    code => code.ISO === countryCode
  )
  const isValidCountryPostalCode =
    countryPostalCodes.length === 0 || //for country without regex
    countryPostalCodes.some(code => code.Regex.test(value))

  if (!isValidCountryPostalCode) {
    return {message: 'postal_code.not_valid'}
  }
}
