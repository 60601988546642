import cookies from 'js-cookie'
import {AppMode} from 'types/AppMode'
import {type IStorageService} from 'types/IStorageService'

////////////////////////////////////////////////////////////////////////////////

export class CookieStorageService implements IStorageService {
  #domain =
    import.meta.env.MODE === AppMode.PROD
      ? import.meta.env.VITE_COOKIE_DOMAIN
      : // Used for testing
        (window.COOKIE_DOMAIN ?? import.meta.env.VITE_COOKIE_DOMAIN)

  #defaultCookieAttributes: Cookies.CookieAttributes = {
    expires: 365,
    domain: this.#domain,
    sameSite: 'lax',
  }

  get<T = any>(key: string): T | undefined {
    const rawValue = cookies.get(key)

    if (rawValue === undefined) {
      return rawValue
    }

    try {
      return JSON.parse(rawValue) as T
    } catch (error) {
      return undefined
    }
  }

  set(key: string, value: any, options?: Cookies.CookieAttributes) {
    const opts = {...this.#defaultCookieAttributes, ...options}

    cookies.set(key, JSON.stringify(value), opts)
  }

  remove(key: string, options?: Cookies.CookieAttributes) {
    const opts = {...this.#defaultCookieAttributes, ...options}

    cookies.remove(key, opts)
  }

  list(): Record<string, string> {
    return Object.fromEntries(
      document.cookie.split('; ').map(c => c.split('='))
    )
  }
}
