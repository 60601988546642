export type MfaData = {
  type?: BackwardCompatibleMfaOption
  code?: string
  phone?: string
}

export type InitialiseMfaSetupData = {
  type?: MfaOption
  country_prefix?: string
  phone_number?: string
}

export type InitialiseMfaSetupResult = {
  qrCode?: string
  secret?: string
}

export type FinishMfaSetupData = {
  type?: BackwardCompatibleMfaOption
  code: string
}

export enum BackwardCompatibleMfaOption {
  TOTP = 'totp',
  SMS = 'sms',
  APP = 'app',
}

export enum MfaOption {
  TOTP = 'totp',
  SMS = 'sms',
}
