import dayjs from 'dayjs'
import {type TFunction} from 'i18next'

export const getDownloadFileName = (t: TFunction, name?: string) => {
  const now = dayjs()

  const date = now.format('YYYY-MM-DD')
  const time = now.format('HH-mm-ss')

  return t('pdf_filename', {
    date,
    time,
    organisation_name: name,
  })
}
