import {App} from 'antd'
import type {MessageInstance} from 'antd/es/message/interface'
import type {NotificationInstance} from 'antd/es/notification/interface'
import {observer} from 'mobx-react-lite'

let message: MessageInstance
let notification: NotificationInstance

const AntAppActions = observer(() => {
  const staticFunction = App.useApp()

  message = staticFunction.message
  notification = staticFunction.notification

  // This component doesn't render anything
  return null
})

export default AntAppActions
export {message, notification}
