import {get} from 'lodash-es'
import {type ValidationRule} from '..'

///////////////////////////////////////////////////////////////////////////////

export type RegexOptions = {
  test: RegExp
}

///////////////////////////////////////////////////////////////////////////////

export const regex: ValidationRule<RegexOptions> = ({field, data, options}) => {
  const value = get<string | undefined>(data, field, '') ?? ''

  if (!value) {
    return
  }

  const {test} = options ?? {}

  if (!test?.test(value)) {
    return {message: 'regex'}
  }
}
