import {type FC, type Ref, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {Checkbox as AntCheckbox, type CheckboxRef} from 'antd'
import {type CheckboxProps as AntCheckboxProps} from 'antd/lib/checkbox'
import cn from 'classnames'
import {get} from 'lodash-es'
import {observer} from 'mobx-react-lite'
import {useNamespace} from 'lib/hooks/useNamespace'
import {useFormHandler} from 'new/hooks/useFormHandler'
import {
  FORM_ITEM_DEFAULT_PROPS,
  FormItem,
  type FormItemBaseProps,
} from '../FormItem'
import styles from './index.module.scss'

///////////////////////////////////////////////////////////////////////////////

export type CheckboxProps = FormItemBaseProps & AntCheckboxProps

///////////////////////////////////////////////////////////////////////////////

export const Checkbox: FC<CheckboxProps> = observer(props => {
  // eslint-disable-next-line prefer-const
  let {ns, prefix} = useNamespace()
  const {t} = useTranslation(ns)
  const ref = useRef<CheckboxRef | undefined>()
  const form = useFormHandler()
  const {values, initialValues} = form

  const {
    name,
    labelIcon,
    registerOnMount,
    unregisterOnUnmount,
    untouchOnUnmount,
    resetOnUnmount,
    disabled,
    errorMessageType,
    showErrorOnUntouched,
    showWarningOnUntouched,
    ...rest
  } = {...CHECKBOX_DEFAULT_PROPS, ...props}

  let {label} = props
  prefix = prefix ? `${prefix}.` : ''
  label ||= t(`${prefix}field_labels.${name}`, {defaultValue: ''})
  label ||= (
    <span
      dangerouslySetInnerHTML={{
        __html: t(`${prefix}field_labels_html.${name}`, {
          defaultValue: '',
        }),
      }}
    />
  )

  const initialValue = !!get<any, any>(initialValues, name, false)
  const value = !!get<any, any>(values, name, false)

  const onChange: AntCheckboxProps['onChange'] = event => {
    form.setFieldValue(name, event.target.checked)
  }

  useEffect(() => {
    const input = ref.current?.input

    const onBlur = () => {
      if (value !== initialValue) {
        form.setFieldTouched(name, true)
      }
    }

    input?.addEventListener('blur', onBlur)

    return () => {
      input?.removeEventListener('blur', onBlur)
    }
  }, [value, initialValue])

  return (
    <FormItem
      name={name}
      skipLabel
      labelIcon={labelIcon}
      className={cn({
        [styles.noStretch]: labelIcon ? true : false,
      })}
      registerOnMount={registerOnMount}
      unregisterOnUnmount={unregisterOnUnmount}
      untouchOnUnmount={untouchOnUnmount}
      resetOnUnmount={resetOnUnmount}
      disabled={disabled}
      errorMessageType={errorMessageType}
      showErrorOnUntouched={showErrorOnUntouched}
      showWarningOnUntouched={showWarningOnUntouched}
    >
      <AntCheckbox
        {...rest}
        ref={ref as Ref<CheckboxRef>}
        name={name}
        disabled={disabled}
        checked={value}
        onChange={onChange}
        data-cy={`checkbox.${name}`}
        data-testid={`checkbox.${name}`}
      >
        {label}
      </AntCheckbox>
    </FormItem>
  )
})

const CHECKBOX_DEFAULT_PROPS: Partial<CheckboxProps> = {
  ...FORM_ITEM_DEFAULT_PROPS,
}
